import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

const KycApplication = () => {
  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <div className="nk-wrap ">
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="kyc-app wide-sm m-auto">
                    <div className="nk-block-head nk-block-head-lg wide-xs mx-auto">
                      <div
                        className="nk-block-head-content text-center"
                        style={{ paddingTop: "4rem" }}
                      >
                        <h2 className="nk-block-title fw-normal text-[28px] lg:text-[40px] font-[400]">
                          KYC Verification
                        </h2>
                        <div className="nk-block-des">
                          <p>
                            To comply with regulation each participant will have
                            to go through indentity verification (KYC/AML) to
                            prevent fraud causes.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="nk-block">
                      <div className="card card-bordered">
                        <div className="card-inner card-inner-lg">
                          <div className="nk-kyc-app p-sm-2 text-center">
                            <div className="nk-kyc-app-icon">
                              <em className="icon ni ni-files" />
                            </div>
                            <div className="nk-kyc-app-text mx-auto">
                              <p className="lead">
                                You have not submitted your necessary documents
                                to verify your identity. In order to complete
                                your registration, please verify your identity.
                              </p>
                            </div>
                            <div className="nk-kyc-app-action">
                              <Link
                                to="/kyc-form"
                                className="btn btn-lg btn-primary"
                              >
                                Click here to complete your KYC
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center pt-4">
                        <p>
                          If you have any question, please contact our support
                          team{" "}
                          <a
                            href="mailto:support@whinstein.com"
                            className="text-[#3a8dfe]"
                          >
                            support@whinstein.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KycApplication;
