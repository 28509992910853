import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Kyc = () => {
  const [kyc, setKyc] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [toolsActive, setToolsActive] = useState(false);
  const [displayedKyc, setDisplayedKyc] = useState(10);
  const [recordsToDisplay, setRecordsToDisplay] = useState(displayedKyc);
  const [pageLoading, setPageLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [idToApprove, setIdtoApprove] = useState("");
  const [idToReject, setIdtoReject] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchingForUser, setSearchingForUser] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [filterSelected, setFilterSelected] = useState("any");

  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);

  const loadMoreRecords = () => {
    setRecordsToDisplay(recordsToDisplay + displayedKyc);
  };

  const toggleSearchActive = () => {
    setSearchActive(!searchActive);
  };

  const toggleToolsActive = () => {
    setToolsActive(!toolsActive);
  };

  const approveKyc = async () => {
    const token = localStorage.getItem("whinsteintkn");
    setLoading(true);

    try {
      await axios.put(
        `https://api.whinstein.com/api/kyc/approve/${user?._id}`,
        { idToApprove, checkedBy: user },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setApproveModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const rejectKyc = async () => {
    const token = localStorage.getItem("whinsteintkn");
    setLoading(true);

    try {
      await axios.put(
        `https://api.whinstein.com/api/kyc/reject/${user?._id}`,
        { idToReject, checkedBy: user },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setRejectModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const deleteKyc = async () => {
    const token = localStorage.getItem("whinsteintkn");
    setLoading(true);

    try {
      await axios.delete(
        `https://api.whinstein.com/api/kyc/${user?._id}/${idToDelete}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setDeleteModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("whinsteintkn");

    const getKyc = async () => {
      const { data } = await axios.get(
        `https://api.whinstein.com/api/kyc/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setKyc(data);
      setPageLoading(false);
    };

    getKyc();
  }, [user?._id]);

  useEffect(() => {
    if (searchingForUser) {
      const foundUser = kyc?.filter((user) => {
        return Object.values(user.user.name)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(foundUser);

      if (foundUser.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  }, [searchingForUser, searchTerm, kyc]);

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between g-3">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">
                          KYC Documents
                        </h3>
                        <div className="nk-block-des text-soft">
                          <p>
                            You have total{" "}
                            <span className="text-base">{kyc.length}</span> KYC
                            documents.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                      <div className="card-inner-group">
                        <div className="card-inner position-relative card-tools-toggle">
                          <div className="card-title-group">
                            <div className="card-tools">
                              {/* <div className="form-inline flex-nowrap gx-3">
                                <div className="form-wrap w-150px">
                                  <select
                                    className="form-select js-select2"
                                    data-search="off"
                                    data-placeholder="Bulk Action"
                                  >
                                    <option value>Bulk Action</option>
                                    <option value="email">Send Email</option>
                                    <option value="group">Change Group</option>
                                    <option value="suspend">
                                      Suspend User
                                    </option>
                                    <option value="delete">Delete User</option>
                                  </select>
                                </div>
                                <div className="btn-wrap">
                                  <span className="d-none d-md-block">
                                    <button className="btn btn-dim btn-outline-light disabled">
                                      Apply
                                    </button>
                                  </span>
                                  <span className="d-md-none">
                                    <button className="btn btn-dim btn-outline-light btn-icon disabled">
                                      <em className="icon ni ni-arrow-right" />
                                    </button>
                                  </span>
                                </div>
                              </div> */}
                            </div>
                            <div className="card-tools me-n1">
                              <ul className="btn-toolbar gx-1">
                                <li>
                                  <a
                                    href="#/"
                                    onClick={toggleSearchActive}
                                    className="btn btn-icon search-toggle toggle-search"
                                    data-target="search"
                                  >
                                    <em className="icon ni ni-search" />
                                  </a>
                                </li>
                                <li className="btn-toolbar-sep" />
                                <li>
                                  <div className="toggle-wrap">
                                    <a
                                      href="#/"
                                      onClick={toggleToolsActive}
                                      className="btn btn-icon btn-trigger toggle"
                                      data-target="cardTools"
                                    >
                                      <em className="icon ni ni-menu-right" />
                                    </a>
                                    <div
                                      className={`toggle-content ${
                                        toolsActive ? "content-active" : ""
                                      }`}
                                      data-content="cardTools"
                                    >
                                      <ul className="btn-toolbar gx-1">
                                        <li className="toggle-close">
                                          <a
                                            href="#/"
                                            onClick={toggleToolsActive}
                                            className="btn btn-icon btn-trigger toggle"
                                            data-target="cardTools"
                                          >
                                            <em className="icon ni ni-arrow-left" />
                                          </a>
                                        </li>
                                        <li>
                                          <div className="dropdown">
                                            <a
                                              href="#/"
                                              className="btn btn-trigger btn-icon dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                            >
                                              <div className="dot dot-primary" />
                                              <em className="icon ni ni-filter-alt" />
                                            </a>
                                            <div className="filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-end">
                                              <div className="dropdown-head">
                                                <span className="sub-title dropdown-title">
                                                  Filter KYC
                                                </span>
                                              </div>
                                              <div className="dropdown-body dropdown-body-rg">
                                                <div className="row gx-6 gy-3">
                                                  <div className="col-6">
                                                    <div className="form-group">
                                                      <label className="overline-title overline-title-alt">
                                                        Status
                                                      </label>
                                                      <select
                                                        className="form-select js-select2"
                                                        onChange={(e) =>
                                                          setFilterSelected(
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option value="any">
                                                          Any Status
                                                        </option>
                                                        <option value="approved">
                                                          Approved
                                                        </option>
                                                        <option value="pending">
                                                          Pending
                                                        </option>
                                                        <option value="rejected">
                                                          Rejected
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="dropdown">
                                            <a
                                              href="#/"
                                              className="btn btn-trigger btn-icon dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                            >
                                              <em className="icon ni ni-setting" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                                              <ul className="link-check">
                                                <li>
                                                  <span>Show</span>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setDisplayedKyc(10)
                                                  }
                                                  className={
                                                    displayedKyc === 10
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <a href="#/">10</a>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setDisplayedKyc(20)
                                                  }
                                                  className={
                                                    displayedKyc === 20
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <a href="#/">20</a>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setDisplayedKyc(50)
                                                  }
                                                  className={
                                                    displayedKyc === 50
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <a href="#/">50</a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            className={`card-search search-wrap ${
                              searchActive ? "active" : ""
                            }`}
                            data-search="search"
                          >
                            <div className="card-body">
                              <div className="search-content">
                                <a
                                  href="#/"
                                  onClick={toggleSearchActive}
                                  className="search-back btn btn-icon toggle-search"
                                  data-target="search"
                                >
                                  <em className="icon ni ni-arrow-left" />
                                </a>
                                <input
                                  type="text"
                                  className="form-control border-transparent form-focus-none"
                                  placeholder="Search by user name"
                                  onChange={(e) => {
                                    setSearchingForUser(true);
                                    setSearchTerm(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner p-0">
                          <div className="nk-tb-list nk-tb-ulist">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col">
                                <span>User</span>
                              </div>
                              <div className="nk-tb-col tb-col-mb">
                                <span>Doc Type</span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span>Document</span>
                              </div>
                              <div className="nk-tb-col tb-col-lg">
                                <span>Submitted</span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span>Status</span>
                              </div>
                              <div className="nk-tb-col tb-col-lg">
                                <span>Checked By</span>
                              </div>
                              <div className="nk-tb-col nk-tb-col-tools">
                                &nbsp;
                              </div>
                            </div>
                            {filterSelected === "any" &&
                              !searchTerm &&
                              kyc?.length > 0 && (
                                <>
                                  {kyc?.slice(0, recordsToDisplay)?.map((k) => (
                                    <div className="nk-tb-item" key={k._id}>
                                      <div className="nk-tb-col">
                                        <div className="user-card">
                                          <div className="user-avatar bg-primary">
                                            <span>
                                              {k.user?.name
                                                .match(/(^\S\S?|\b\S)?/g)
                                                .join("")
                                                .match(/(^\S|\S$)?/g)
                                                .join("")
                                                .toUpperCase()}
                                            </span>
                                          </div>
                                          <div className="user-info">
                                            <span className="tb-lead">
                                              {k.user.name}
                                              {k.status === "Approved" && (
                                                <span className="dot dot-success d-md-none ms-1" />
                                              )}
                                              {k.status === "Pending" && (
                                                <span className="dot dot-info d-md-none ms-1" />
                                              )}
                                              {k.status === "Rejected" && (
                                                <span className="dot dot-danger d-md-none ms-1" />
                                              )}
                                            </span>
                                            <span>{k.user.email}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="nk-tb-col tb-col-mb">
                                        <span className="tb-lead-sub">
                                          {k.id}
                                        </span>
                                      </div>
                                      <div className="nk-tb-col tb-col-md">
                                        <ul className="list-inline list-download">
                                          <li>
                                            View{" "}
                                            <a
                                              href={k.document}
                                              className="popup"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <em className="icon ni ni-download" />
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="nk-tb-col tb-col-lg">
                                        <span className="tb-date">
                                          {new Date(
                                            k.createdAt
                                          ).toLocaleDateString()}
                                        </span>
                                      </div>
                                      <div className="nk-tb-col tb-col-md">
                                        {k.status === "Pending" && (
                                          <span className="tb-status text-info">
                                            {k.status}
                                          </span>
                                        )}
                                        {k.status === "Approved" && (
                                          <span className="tb-status text-success">
                                            {k.status}
                                          </span>
                                        )}
                                        {k.status === "Rejected" && (
                                          <span className="tb-status text-danger">
                                            {k.status}
                                          </span>
                                        )}
                                      </div>
                                      {k.checkedBy ? (
                                        <div className="nk-tb-col tb-col-lg">
                                          <div className="user-card">
                                            <div className="user-avatar user-avatar-xs bg-orange-dim">
                                              <span>
                                                {k.checkedBy?.name
                                                  .match(/(^\S\S?|\b\S)?/g)
                                                  .join("")
                                                  .match(/(^\S|\S$)?/g)
                                                  .join("")
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                            <div className="user-name">
                                              <span className="tb-lead">
                                                {k.checkedBy?.name}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="nk-tb-col tb-col-lg">
                                          -
                                        </div>
                                      )}
                                      <div className="nk-tb-col nk-tb-col-tools">
                                        <ul className="nk-tb-actions gx-1">
                                          {/* <li className="nk-tb-action-hidden">
                                          <a
                                            href="kyc-details-regular.html"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Quick View"
                                          >
                                            <em className="icon ni ni-eye-fill" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Approved"
                                          >
                                            <em className="icon ni ni-check-fill-c" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Rejected"
                                          >
                                            <em className="icon ni ni-cross-fill-c" />
                                          </a>
                                        </li> */}
                                          <li>
                                            <div className="drodown">
                                              <a
                                                href="#/"
                                                className="dropdown-toggle btn btn-icon btn-trigger"
                                                data-bs-toggle="dropdown"
                                              >
                                                <em className="icon ni ni-more-h" />
                                              </a>
                                              <div className="dropdown-menu dropdown-menu-end">
                                                <ul className="link-list-opt no-bdr">
                                                  <li>
                                                    <Link
                                                      to={`/admin/kyc/${k._id}`}
                                                    >
                                                      <em className="icon ni ni-eye" />
                                                      <span>View Details</span>
                                                    </Link>
                                                  </li>
                                                  <li>
                                                    <Link
                                                      to={`/admin/user/${k.user._id}`}
                                                    >
                                                      <em className="icon ni ni-user" />
                                                      <span>View Profile</span>
                                                    </Link>
                                                  </li>
                                                  <li className="divider" />
                                                  {k.status === "Pending" && (
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setApproveModalOpen(
                                                            true
                                                          );
                                                          setIdtoApprove(k._id);
                                                        }}
                                                      >
                                                        <em className="icon ni ni-check-round" />
                                                        <span>Approve</span>
                                                      </a>
                                                    </li>
                                                  )}
                                                  {k.status === "Pending" && (
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setRejectModalOpen(
                                                            true
                                                          );
                                                          setIdtoReject(k._id);
                                                        }}
                                                      >
                                                        <em className="icon ni ni-na" />
                                                        <span>Reject</span>
                                                      </a>
                                                    </li>
                                                  )}
                                                  <li>
                                                    <a
                                                      href="#/"
                                                      onClick={() => {
                                                        setDeleteModalOpen(
                                                          true
                                                        );
                                                        setIdToDelete(k._id);
                                                      }}
                                                    >
                                                      <em className="icon ni ni-trash" />
                                                      <span>Delete</span>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                            {filterSelected === "approved" &&
                              !searchTerm &&
                              kyc?.length > 0 && (
                                <>
                                  {kyc
                                    ?.filter((k) => k.status === "Approved")
                                    ?.map((k) => (
                                      <div className="nk-tb-item" key={k._id}>
                                        <div className="nk-tb-col">
                                          <div className="user-card">
                                            <div className="user-avatar bg-primary">
                                              <span>
                                                {k.user?.name
                                                  .match(/(^\S\S?|\b\S)?/g)
                                                  .join("")
                                                  .match(/(^\S|\S$)?/g)
                                                  .join("")
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                            <div className="user-info">
                                              <span className="tb-lead">
                                                {k.user.name}
                                                {k.status === "Approved" && (
                                                  <span className="dot dot-success d-md-none ms-1" />
                                                )}
                                                {k.status === "Pending" && (
                                                  <span className="dot dot-info d-md-none ms-1" />
                                                )}
                                                {k.status === "Rejected" && (
                                                  <span className="dot dot-danger d-md-none ms-1" />
                                                )}
                                              </span>
                                              <span>{k.user.email}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-tb-col tb-col-mb">
                                          <span className="tb-lead-sub">
                                            {k.id}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <ul className="list-inline list-download">
                                            <li>
                                              View{" "}
                                              <a
                                                href={k.document}
                                                className="popup"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <em className="icon ni ni-download" />
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="nk-tb-col tb-col-lg">
                                          <span className="tb-date">
                                            {new Date(
                                              k.createdAt
                                            ).toLocaleDateString()}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          {k.status === "Pending" && (
                                            <span className="tb-status text-info">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Approved" && (
                                            <span className="tb-status text-success">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Rejected" && (
                                            <span className="tb-status text-danger">
                                              {k.status}
                                            </span>
                                          )}
                                        </div>
                                        {k.checkedBy ? (
                                          <div className="nk-tb-col tb-col-lg">
                                            <div className="user-card">
                                              <div className="user-avatar user-avatar-xs bg-orange-dim">
                                                <span>
                                                  {k.checkedBy?.name
                                                    .match(/(^\S\S?|\b\S)?/g)
                                                    .join("")
                                                    .match(/(^\S|\S$)?/g)
                                                    .join("")
                                                    .toUpperCase()}
                                                </span>
                                              </div>
                                              <div className="user-name">
                                                <span className="tb-lead">
                                                  {k.checkedBy?.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="nk-tb-col tb-col-lg">
                                            -
                                          </div>
                                        )}
                                        <div className="nk-tb-col nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-1">
                                            {/* <li className="nk-tb-action-hidden">
                                          <a
                                            href="kyc-details-regular.html"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Quick View"
                                          >
                                            <em className="icon ni ni-eye-fill" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Approved"
                                          >
                                            <em className="icon ni ni-check-fill-c" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Rejected"
                                          >
                                            <em className="icon ni ni-cross-fill-c" />
                                          </a>
                                        </li> */}
                                            <li>
                                              <div className="drodown">
                                                <a
                                                  href="#/"
                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <Link
                                                        to={`/admin/kyc/${k._id}`}
                                                      >
                                                        <em className="icon ni ni-eye" />
                                                        <span>
                                                          View Details
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <Link
                                                        to={`/admin/user/${k.user._id}`}
                                                      >
                                                        <em className="icon ni ni-user" />
                                                        <span>
                                                          View Profile
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li className="divider" />
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setApproveModalOpen(
                                                              true
                                                            );
                                                            setIdtoApprove(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-check-round" />
                                                          <span>Approve</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setRejectModalOpen(
                                                              true
                                                            );
                                                            setIdtoReject(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-na" />
                                                          <span>Reject</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setDeleteModalOpen(
                                                            true
                                                          );
                                                          setIdToDelete(k._id);
                                                        }}
                                                      >
                                                        <em className="icon ni ni-trash" />
                                                        <span>Delete</span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              )}
                            {filterSelected === "pending" &&
                              !searchTerm &&
                              kyc?.length > 0 && (
                                <>
                                  {kyc
                                    ?.filter((k) => k.status === "Pending")
                                    ?.map((k) => (
                                      <div className="nk-tb-item" key={k._id}>
                                        <div className="nk-tb-col">
                                          <div className="user-card">
                                            <div className="user-avatar bg-primary">
                                              <span>
                                                {k.user?.name
                                                  .match(/(^\S\S?|\b\S)?/g)
                                                  .join("")
                                                  .match(/(^\S|\S$)?/g)
                                                  .join("")
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                            <div className="user-info">
                                              <span className="tb-lead">
                                                {k.user.name}
                                                {k.status === "Approved" && (
                                                  <span className="dot dot-success d-md-none ms-1" />
                                                )}
                                                {k.status === "Pending" && (
                                                  <span className="dot dot-info d-md-none ms-1" />
                                                )}
                                                {k.status === "Rejected" && (
                                                  <span className="dot dot-danger d-md-none ms-1" />
                                                )}
                                              </span>
                                              <span>{k.user.email}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-tb-col tb-col-mb">
                                          <span className="tb-lead-sub">
                                            {k.id}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <ul className="list-inline list-download">
                                            <li>
                                              View{" "}
                                              <a
                                                href={k.document}
                                                className="popup"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <em className="icon ni ni-download" />
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="nk-tb-col tb-col-lg">
                                          <span className="tb-date">
                                            {new Date(
                                              k.createdAt
                                            ).toLocaleDateString()}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          {k.status === "Pending" && (
                                            <span className="tb-status text-info">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Approved" && (
                                            <span className="tb-status text-success">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Rejected" && (
                                            <span className="tb-status text-danger">
                                              {k.status}
                                            </span>
                                          )}
                                        </div>
                                        {k.checkedBy ? (
                                          <div className="nk-tb-col tb-col-lg">
                                            <div className="user-card">
                                              <div className="user-avatar user-avatar-xs bg-orange-dim">
                                                <span>
                                                  {k.checkedBy?.name
                                                    .match(/(^\S\S?|\b\S)?/g)
                                                    .join("")
                                                    .match(/(^\S|\S$)?/g)
                                                    .join("")
                                                    .toUpperCase()}
                                                </span>
                                              </div>
                                              <div className="user-name">
                                                <span className="tb-lead">
                                                  {k.checkedBy?.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="nk-tb-col tb-col-lg">
                                            -
                                          </div>
                                        )}
                                        <div className="nk-tb-col nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-1">
                                            {/* <li className="nk-tb-action-hidden">
                                          <a
                                            href="kyc-details-regular.html"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Quick View"
                                          >
                                            <em className="icon ni ni-eye-fill" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Approved"
                                          >
                                            <em className="icon ni ni-check-fill-c" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Rejected"
                                          >
                                            <em className="icon ni ni-cross-fill-c" />
                                          </a>
                                        </li> */}
                                            <li>
                                              <div className="drodown">
                                                <a
                                                  href="#/"
                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <Link
                                                        to={`/admin/kyc/${k._id}`}
                                                      >
                                                        <em className="icon ni ni-eye" />
                                                        <span>
                                                          View Details
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <Link
                                                        to={`/admin/user/${k.user._id}`}
                                                      >
                                                        <em className="icon ni ni-user" />
                                                        <span>
                                                          View Profile
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li className="divider" />
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setApproveModalOpen(
                                                              true
                                                            );
                                                            setIdtoApprove(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-check-round" />
                                                          <span>Approve</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setRejectModalOpen(
                                                              true
                                                            );
                                                            setIdtoReject(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-na" />
                                                          <span>Reject</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setDeleteModalOpen(
                                                            true
                                                          );
                                                          setIdToDelete(k._id);
                                                        }}
                                                      >
                                                        <em className="icon ni ni-trash" />
                                                        <span>Delete</span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              )}
                            {filterSelected === "rejected" &&
                              !searchTerm &&
                              kyc?.length > 0 && (
                                <>
                                  {kyc
                                    ?.filter((k) => k.status === "Rejected")
                                    ?.map((k) => (
                                      <div className="nk-tb-item" key={k._id}>
                                        <div className="nk-tb-col">
                                          <div className="user-card">
                                            <div className="user-avatar bg-primary">
                                              <span>
                                                {k.user?.name
                                                  .match(/(^\S\S?|\b\S)?/g)
                                                  .join("")
                                                  .match(/(^\S|\S$)?/g)
                                                  .join("")
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                            <div className="user-info">
                                              <span className="tb-lead">
                                                {k.user.name}
                                                {k.status === "Approved" && (
                                                  <span className="dot dot-success d-md-none ms-1" />
                                                )}
                                                {k.status === "Pending" && (
                                                  <span className="dot dot-info d-md-none ms-1" />
                                                )}
                                                {k.status === "Rejected" && (
                                                  <span className="dot dot-danger d-md-none ms-1" />
                                                )}
                                              </span>
                                              <span>{k.user.email}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-tb-col tb-col-mb">
                                          <span className="tb-lead-sub">
                                            {k.id}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <ul className="list-inline list-download">
                                            <li>
                                              View{" "}
                                              <a
                                                href={k.document}
                                                className="popup"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <em className="icon ni ni-download" />
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="nk-tb-col tb-col-lg">
                                          <span className="tb-date">
                                            {new Date(
                                              k.createdAt
                                            ).toLocaleDateString()}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          {k.status === "Pending" && (
                                            <span className="tb-status text-info">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Approved" && (
                                            <span className="tb-status text-success">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Rejected" && (
                                            <span className="tb-status text-danger">
                                              {k.status}
                                            </span>
                                          )}
                                        </div>
                                        {k.checkedBy ? (
                                          <div className="nk-tb-col tb-col-lg">
                                            <div className="user-card">
                                              <div className="user-avatar user-avatar-xs bg-orange-dim">
                                                <span>
                                                  {k.checkedBy?.name
                                                    .match(/(^\S\S?|\b\S)?/g)
                                                    .join("")
                                                    .match(/(^\S|\S$)?/g)
                                                    .join("")
                                                    .toUpperCase()}
                                                </span>
                                              </div>
                                              <div className="user-name">
                                                <span className="tb-lead">
                                                  {k.checkedBy?.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="nk-tb-col tb-col-lg">
                                            -
                                          </div>
                                        )}
                                        <div className="nk-tb-col nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-1">
                                            {/* <li className="nk-tb-action-hidden">
                                          <a
                                            href="kyc-details-regular.html"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Quick View"
                                          >
                                            <em className="icon ni ni-eye-fill" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Approved"
                                          >
                                            <em className="icon ni ni-check-fill-c" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Rejected"
                                          >
                                            <em className="icon ni ni-cross-fill-c" />
                                          </a>
                                        </li> */}
                                            <li>
                                              <div className="drodown">
                                                <a
                                                  href="#/"
                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <Link
                                                        to={`/admin/kyc/${k._id}`}
                                                      >
                                                        <em className="icon ni ni-eye" />
                                                        <span>
                                                          View Details
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <Link
                                                        to={`/admin/user/${k.user._id}`}
                                                      >
                                                        <em className="icon ni ni-user" />
                                                        <span>
                                                          View Profile
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li className="divider" />
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setApproveModalOpen(
                                                              true
                                                            );
                                                            setIdtoApprove(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-check-round" />
                                                          <span>Approve</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setRejectModalOpen(
                                                              true
                                                            );
                                                            setIdtoReject(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-na" />
                                                          <span>Reject</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setDeleteModalOpen(
                                                            true
                                                          );
                                                          setIdToDelete(k._id);
                                                        }}
                                                      >
                                                        <em className="icon ni ni-trash" />
                                                        <span>Delete</span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              )}
                            {filterSelected === "any" &&
                              searchTerm &&
                              searchResults?.length > 0 && (
                                <>
                                  {searchResults?.map((k) => (
                                    <div className="nk-tb-item" key={k._id}>
                                      <div className="nk-tb-col">
                                        <div className="user-card">
                                          <div className="user-avatar bg-primary">
                                            <span>
                                              {k.user?.name
                                                .match(/(^\S\S?|\b\S)?/g)
                                                .join("")
                                                .match(/(^\S|\S$)?/g)
                                                .join("")
                                                .toUpperCase()}
                                            </span>
                                          </div>
                                          <div className="user-info">
                                            <span className="tb-lead">
                                              {k.user.name}
                                              {k.status === "Approved" && (
                                                <span className="dot dot-success d-md-none ms-1" />
                                              )}
                                              {k.status === "Pending" && (
                                                <span className="dot dot-info d-md-none ms-1" />
                                              )}
                                              {k.status === "Rejected" && (
                                                <span className="dot dot-danger d-md-none ms-1" />
                                              )}
                                            </span>
                                            <span>{k.user.email}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="nk-tb-col tb-col-mb">
                                        <span className="tb-lead-sub">
                                          {k.id}
                                        </span>
                                      </div>
                                      <div className="nk-tb-col tb-col-md">
                                        <ul className="list-inline list-download">
                                          <li>
                                            View{" "}
                                            <a
                                              href={k.document}
                                              className="popup"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <em className="icon ni ni-download" />
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="nk-tb-col tb-col-lg">
                                        <span className="tb-date">
                                          {new Date(
                                            k.createdAt
                                          ).toLocaleDateString()}
                                        </span>
                                      </div>
                                      <div className="nk-tb-col tb-col-md">
                                        {k.status === "Pending" && (
                                          <span className="tb-status text-info">
                                            {k.status}
                                          </span>
                                        )}
                                        {k.status === "Approved" && (
                                          <span className="tb-status text-success">
                                            {k.status}
                                          </span>
                                        )}
                                        {k.status === "Rejected" && (
                                          <span className="tb-status text-danger">
                                            {k.status}
                                          </span>
                                        )}
                                      </div>
                                      {k.checkedBy ? (
                                        <div className="nk-tb-col tb-col-lg">
                                          <div className="user-card">
                                            <div className="user-avatar user-avatar-xs bg-orange-dim">
                                              <span>
                                                {k.checkedBy?.name
                                                  .match(/(^\S\S?|\b\S)?/g)
                                                  .join("")
                                                  .match(/(^\S|\S$)?/g)
                                                  .join("")
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                            <div className="user-name">
                                              <span className="tb-lead">
                                                {k.checkedBy?.name}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="nk-tb-col tb-col-lg">
                                          -
                                        </div>
                                      )}
                                      <div className="nk-tb-col nk-tb-col-tools">
                                        <ul className="nk-tb-actions gx-1">
                                          {/* <li className="nk-tb-action-hidden">
                                          <a
                                            href="kyc-details-regular.html"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Quick View"
                                          >
                                            <em className="icon ni ni-eye-fill" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Approved"
                                          >
                                            <em className="icon ni ni-check-fill-c" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Rejected"
                                          >
                                            <em className="icon ni ni-cross-fill-c" />
                                          </a>
                                        </li> */}
                                          <li>
                                            <div className="drodown">
                                              <a
                                                href="#/"
                                                className="dropdown-toggle btn btn-icon btn-trigger"
                                                data-bs-toggle="dropdown"
                                              >
                                                <em className="icon ni ni-more-h" />
                                              </a>
                                              <div className="dropdown-menu dropdown-menu-end">
                                                <ul className="link-list-opt no-bdr">
                                                  <li>
                                                    <Link
                                                      to={`/admin/kyc/${k._id}`}
                                                    >
                                                      <em className="icon ni ni-eye" />
                                                      <span>View Details</span>
                                                    </Link>
                                                  </li>
                                                  <li>
                                                    <Link
                                                      to={`/admin/user/${k.user._id}`}
                                                    >
                                                      <em className="icon ni ni-user" />
                                                      <span>View Profile</span>
                                                    </Link>
                                                  </li>
                                                  <li className="divider" />
                                                  {k.status === "Pending" && (
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setApproveModalOpen(
                                                            true
                                                          );
                                                          setIdtoApprove(k._id);
                                                        }}
                                                      >
                                                        <em className="icon ni ni-check-round" />
                                                        <span>Approve</span>
                                                      </a>
                                                    </li>
                                                  )}
                                                  {k.status === "Pending" && (
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setRejectModalOpen(
                                                            true
                                                          );
                                                          setIdtoReject(k._id);
                                                        }}
                                                      >
                                                        <em className="icon ni ni-na" />
                                                        <span>Reject</span>
                                                      </a>
                                                    </li>
                                                  )}
                                                  <li>
                                                    <a
                                                      href="#/"
                                                      onClick={() => {
                                                        setDeleteModalOpen(
                                                          true
                                                        );
                                                        setIdToDelete(k._id);
                                                      }}
                                                    >
                                                      <em className="icon ni ni-trash" />
                                                      <span>Delete</span>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                            {filterSelected === "approved" &&
                              searchTerm &&
                              searchResults?.length > 0 && (
                                <>
                                  {searchResults
                                    ?.filter((k) => k.status === "Approved")
                                    ?.map((k) => (
                                      <div className="nk-tb-item" key={k._id}>
                                        <div className="nk-tb-col">
                                          <div className="user-card">
                                            <div className="user-avatar bg-primary">
                                              <span>
                                                {k.user?.name
                                                  .match(/(^\S\S?|\b\S)?/g)
                                                  .join("")
                                                  .match(/(^\S|\S$)?/g)
                                                  .join("")
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                            <div className="user-info">
                                              <span className="tb-lead">
                                                {k.user.name}
                                                {k.status === "Approved" && (
                                                  <span className="dot dot-success d-md-none ms-1" />
                                                )}
                                                {k.status === "Pending" && (
                                                  <span className="dot dot-info d-md-none ms-1" />
                                                )}
                                                {k.status === "Rejected" && (
                                                  <span className="dot dot-danger d-md-none ms-1" />
                                                )}
                                              </span>
                                              <span>{k.user.email}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-tb-col tb-col-mb">
                                          <span className="tb-lead-sub">
                                            {k.id}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <ul className="list-inline list-download">
                                            <li>
                                              View{" "}
                                              <a
                                                href={k.document}
                                                className="popup"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <em className="icon ni ni-download" />
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="nk-tb-col tb-col-lg">
                                          <span className="tb-date">
                                            {new Date(
                                              k.createdAt
                                            ).toLocaleDateString()}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          {k.status === "Pending" && (
                                            <span className="tb-status text-info">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Approved" && (
                                            <span className="tb-status text-success">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Rejected" && (
                                            <span className="tb-status text-danger">
                                              {k.status}
                                            </span>
                                          )}
                                        </div>
                                        {k.checkedBy ? (
                                          <div className="nk-tb-col tb-col-lg">
                                            <div className="user-card">
                                              <div className="user-avatar user-avatar-xs bg-orange-dim">
                                                <span>
                                                  {k.checkedBy?.name
                                                    .match(/(^\S\S?|\b\S)?/g)
                                                    .join("")
                                                    .match(/(^\S|\S$)?/g)
                                                    .join("")
                                                    .toUpperCase()}
                                                </span>
                                              </div>
                                              <div className="user-name">
                                                <span className="tb-lead">
                                                  {k.checkedBy?.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="nk-tb-col tb-col-lg">
                                            -
                                          </div>
                                        )}
                                        <div className="nk-tb-col nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-1">
                                            {/* <li className="nk-tb-action-hidden">
                                          <a
                                            href="kyc-details-regular.html"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Quick View"
                                          >
                                            <em className="icon ni ni-eye-fill" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Approved"
                                          >
                                            <em className="icon ni ni-check-fill-c" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Rejected"
                                          >
                                            <em className="icon ni ni-cross-fill-c" />
                                          </a>
                                        </li> */}
                                            <li>
                                              <div className="drodown">
                                                <a
                                                  href="#/"
                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <Link
                                                        to={`/admin/kyc/${k._id}`}
                                                      >
                                                        <em className="icon ni ni-eye" />
                                                        <span>
                                                          View Details
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <Link
                                                        to={`/admin/user/${k.user._id}`}
                                                      >
                                                        <em className="icon ni ni-user" />
                                                        <span>
                                                          View Profile
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li className="divider" />
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setApproveModalOpen(
                                                              true
                                                            );
                                                            setIdtoApprove(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-check-round" />
                                                          <span>Approve</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setRejectModalOpen(
                                                              true
                                                            );
                                                            setIdtoReject(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-na" />
                                                          <span>Reject</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setDeleteModalOpen(
                                                            true
                                                          );
                                                          setIdToDelete(k._id);
                                                        }}
                                                      >
                                                        <em className="icon ni ni-trash" />
                                                        <span>Delete</span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              )}
                            {filterSelected === "pending" &&
                              searchTerm &&
                              searchResults?.length > 0 && (
                                <>
                                  {searchResults
                                    ?.filter((k) => k.status === "Pending")
                                    ?.map((k) => (
                                      <div className="nk-tb-item" key={k._id}>
                                        <div className="nk-tb-col">
                                          <div className="user-card">
                                            <div className="user-avatar bg-primary">
                                              <span>
                                                {k.user?.name
                                                  .match(/(^\S\S?|\b\S)?/g)
                                                  .join("")
                                                  .match(/(^\S|\S$)?/g)
                                                  .join("")
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                            <div className="user-info">
                                              <span className="tb-lead">
                                                {k.user.name}
                                                {k.status === "Approved" && (
                                                  <span className="dot dot-success d-md-none ms-1" />
                                                )}
                                                {k.status === "Pending" && (
                                                  <span className="dot dot-info d-md-none ms-1" />
                                                )}
                                                {k.status === "Rejected" && (
                                                  <span className="dot dot-danger d-md-none ms-1" />
                                                )}
                                              </span>
                                              <span>{k.user.email}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-tb-col tb-col-mb">
                                          <span className="tb-lead-sub">
                                            {k.id}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <ul className="list-inline list-download">
                                            <li>
                                              View{" "}
                                              <a
                                                href={k.document}
                                                className="popup"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <em className="icon ni ni-download" />
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="nk-tb-col tb-col-lg">
                                          <span className="tb-date">
                                            {new Date(
                                              k.createdAt
                                            ).toLocaleDateString()}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          {k.status === "Pending" && (
                                            <span className="tb-status text-info">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Approved" && (
                                            <span className="tb-status text-success">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Rejected" && (
                                            <span className="tb-status text-danger">
                                              {k.status}
                                            </span>
                                          )}
                                        </div>
                                        {k.checkedBy ? (
                                          <div className="nk-tb-col tb-col-lg">
                                            <div className="user-card">
                                              <div className="user-avatar user-avatar-xs bg-orange-dim">
                                                <span>
                                                  {k.checkedBy?.name
                                                    .match(/(^\S\S?|\b\S)?/g)
                                                    .join("")
                                                    .match(/(^\S|\S$)?/g)
                                                    .join("")
                                                    .toUpperCase()}
                                                </span>
                                              </div>
                                              <div className="user-name">
                                                <span className="tb-lead">
                                                  {k.checkedBy?.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="nk-tb-col tb-col-lg">
                                            -
                                          </div>
                                        )}
                                        <div className="nk-tb-col nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-1">
                                            {/* <li className="nk-tb-action-hidden">
                                          <a
                                            href="kyc-details-regular.html"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Quick View"
                                          >
                                            <em className="icon ni ni-eye-fill" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Approved"
                                          >
                                            <em className="icon ni ni-check-fill-c" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Rejected"
                                          >
                                            <em className="icon ni ni-cross-fill-c" />
                                          </a>
                                        </li> */}
                                            <li>
                                              <div className="drodown">
                                                <a
                                                  href="#/"
                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <Link
                                                        to={`/admin/kyc/${k._id}`}
                                                      >
                                                        <em className="icon ni ni-eye" />
                                                        <span>
                                                          View Details
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <Link
                                                        to={`/admin/user/${k.user._id}`}
                                                      >
                                                        <em className="icon ni ni-user" />
                                                        <span>
                                                          View Profile
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li className="divider" />
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setApproveModalOpen(
                                                              true
                                                            );
                                                            setIdtoApprove(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-check-round" />
                                                          <span>Approve</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setRejectModalOpen(
                                                              true
                                                            );
                                                            setIdtoReject(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-na" />
                                                          <span>Reject</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setDeleteModalOpen(
                                                            true
                                                          );
                                                          setIdToDelete(k._id);
                                                        }}
                                                      >
                                                        <em className="icon ni ni-trash" />
                                                        <span>Delete</span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              )}
                            {filterSelected === "rejected" &&
                              searchTerm &&
                              searchResults?.length > 0 && (
                                <>
                                  {searchResults
                                    ?.filter((k) => k.status === "Rejected")
                                    ?.map((k) => (
                                      <div className="nk-tb-item" key={k._id}>
                                        <div className="nk-tb-col">
                                          <div className="user-card">
                                            <div className="user-avatar bg-primary">
                                              <span>
                                                {k.user?.name
                                                  .match(/(^\S\S?|\b\S)?/g)
                                                  .join("")
                                                  .match(/(^\S|\S$)?/g)
                                                  .join("")
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                            <div className="user-info">
                                              <span className="tb-lead">
                                                {k.user.name}
                                                {k.status === "Approved" && (
                                                  <span className="dot dot-success d-md-none ms-1" />
                                                )}
                                                {k.status === "Pending" && (
                                                  <span className="dot dot-info d-md-none ms-1" />
                                                )}
                                                {k.status === "Rejected" && (
                                                  <span className="dot dot-danger d-md-none ms-1" />
                                                )}
                                              </span>
                                              <span>{k.user.email}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-tb-col tb-col-mb">
                                          <span className="tb-lead-sub">
                                            {k.id}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <ul className="list-inline list-download">
                                            <li>
                                              View{" "}
                                              <a
                                                href={k.document}
                                                className="popup"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <em className="icon ni ni-download" />
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="nk-tb-col tb-col-lg">
                                          <span className="tb-date">
                                            {new Date(
                                              k.createdAt
                                            ).toLocaleDateString()}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          {k.status === "Pending" && (
                                            <span className="tb-status text-info">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Approved" && (
                                            <span className="tb-status text-success">
                                              {k.status}
                                            </span>
                                          )}
                                          {k.status === "Rejected" && (
                                            <span className="tb-status text-danger">
                                              {k.status}
                                            </span>
                                          )}
                                        </div>
                                        {k.checkedBy ? (
                                          <div className="nk-tb-col tb-col-lg">
                                            <div className="user-card">
                                              <div className="user-avatar user-avatar-xs bg-orange-dim">
                                                <span>
                                                  {k.checkedBy?.name
                                                    .match(/(^\S\S?|\b\S)?/g)
                                                    .join("")
                                                    .match(/(^\S|\S$)?/g)
                                                    .join("")
                                                    .toUpperCase()}
                                                </span>
                                              </div>
                                              <div className="user-name">
                                                <span className="tb-lead">
                                                  {k.checkedBy?.name}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="nk-tb-col tb-col-lg">
                                            -
                                          </div>
                                        )}
                                        <div className="nk-tb-col nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-1">
                                            {/* <li className="nk-tb-action-hidden">
                                          <a
                                            href="kyc-details-regular.html"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Quick View"
                                          >
                                            <em className="icon ni ni-eye-fill" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Approved"
                                          >
                                            <em className="icon ni ni-check-fill-c" />
                                          </a>
                                        </li>
                                        <li className="nk-tb-action-hidden">
                                          <a
                                            href="#/"
                                            className="btn btn-trigger btn-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Rejected"
                                          >
                                            <em className="icon ni ni-cross-fill-c" />
                                          </a>
                                        </li> */}
                                            <li>
                                              <div className="drodown">
                                                <a
                                                  href="#/"
                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <Link
                                                        to={`/admin/kyc/${k._id}`}
                                                      >
                                                        <em className="icon ni ni-eye" />
                                                        <span>
                                                          View Details
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <Link
                                                        to={`/admin/user/${k.user._id}`}
                                                      >
                                                        <em className="icon ni ni-user" />
                                                        <span>
                                                          View Profile
                                                        </span>
                                                      </Link>
                                                    </li>
                                                    <li className="divider" />
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setApproveModalOpen(
                                                              true
                                                            );
                                                            setIdtoApprove(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-check-round" />
                                                          <span>Approve</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    {k.status === "Pending" && (
                                                      <li>
                                                        <a
                                                          href="#/"
                                                          onClick={() => {
                                                            setRejectModalOpen(
                                                              true
                                                            );
                                                            setIdtoReject(
                                                              k._id
                                                            );
                                                          }}
                                                        >
                                                          <em className="icon ni ni-na" />
                                                          <span>Reject</span>
                                                        </a>
                                                      </li>
                                                    )}
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setDeleteModalOpen(
                                                            true
                                                          );
                                                          setIdToDelete(k._id);
                                                        }}
                                                      >
                                                        <em className="icon ni ni-trash" />
                                                        <span>Delete</span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              )}
                            {(noResults || kyc?.length === 0) && (
                              <p
                                style={{
                                  position: "relative",
                                  left: "50%",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                No records
                              </p>
                            )}
                          </div>
                        </div>
                        {/* <div className="card-inner">
                          <ul className="pagination justify-content-center justify-content-md-start">
                            <li className="page-item">
                              <a className="page-link" href="#/">
                                Prev
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#/">
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#/">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <span className="page-link">
                                <em className="icon ni ni-more-h" />
                              </span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#/">
                                6
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#/">
                                7
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#/">
                                Next
                              </a>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {recordsToDisplay < kyc?.length && (
                <div className="text-center pt-4">
                  <a
                    href="#/"
                    className="link link-soft"
                    onClick={loadMoreRecords}
                  >
                    <em className="icon ni ni-redo" />
                    <span>Load More</span>
                  </a>
                </div>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="#0971fe"
      />

      {deleteModalOpen && (
        <>
          <div className="modal-backdrop fade show" />
          <div
            className="modal fade show"
            id="deleteEventPopup"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal py-4">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger" />
                    <h4 className="nk-modal-title">Are You Sure?</h4>
                    <div className="nk-modal-text mt-n2">
                      <p className="text-soft">
                        This record will be removed permanently.
                      </p>
                    </div>
                    <ul className="d-flex justify-content-center gx-4 mt-4">
                      <li>
                        {loading ? (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            disabled
                          >
                            Deleting...
                          </button>
                        ) : (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            onClick={deleteKyc}
                          >
                            Yes, Delete it
                          </button>
                        )}
                      </li>
                      <li>
                        <button
                          className="btn btn-danger btn-dim"
                          onClick={() => setDeleteModalOpen(false)}
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {approveModalOpen && (
        <>
          <div className="modal-backdrop fade show" />
          <div
            className="modal fade show"
            id="deleteEventPopup"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal py-4">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Are You Sure?</h4>
                    <ul className="d-flex justify-content-center gx-4 mt-4">
                      <li>
                        {loading ? (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            disabled
                          >
                            Approving...
                          </button>
                        ) : (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            onClick={approveKyc}
                          >
                            Yes, Approve it
                          </button>
                        )}
                      </li>
                      <li>
                        <button
                          className="btn btn-danger btn-dim"
                          onClick={() => setApproveModalOpen(false)}
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {rejectModalOpen && (
        <>
          <div className="modal-backdrop fade show" />
          <div
            className="modal fade show"
            id="deleteEventPopup"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal py-4">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Are You Sure?</h4>
                    <ul className="d-flex justify-content-center gx-4 mt-4">
                      <li>
                        {loading ? (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            disabled
                          >
                            Rejecting...
                          </button>
                        ) : (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            onClick={rejectKyc}
                          >
                            Yes, Reject it
                          </button>
                        )}
                      </li>
                      <li>
                        <button
                          className="btn btn-danger btn-dim"
                          onClick={() => setRejectModalOpen(false)}
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />
    </div>
  );
};

export default Kyc;
