import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";

const Profile = () => {
  const [sideOpen, setSideOpen] = useState(false);

  const user = useSelector((state) => state.user.currentUser);

  const initials = user?.name
    .match(/(^\S\S?|\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();

  const toggleSide = () => {
    setSideOpen(!sideOpen);
  };

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="nk-block">
                    <div className="card card-bordered">
                      <div className="card-aside-wrap">
                        <div className="card-inner card-inner-lg">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between">
                              <div className="nk-block-head-content">
                                <h4
                                  className="nk-block-title"
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    marginBottom: "8px",
                                  }}
                                >
                                  Personal Information
                                </h4>
                                <div className="nk-block-des">
                                  <p>
                                    Basic info like your name and email address
                                    that you use on Whinstein.
                                  </p>
                                </div>
                              </div>
                              <div className="nk-block-head-content align-self-start d-lg-none">
                                <a
                                  href="#/"
                                  onClick={toggleSide}
                                  className="toggle btn btn-icon btn-trigger mt-n1"
                                  data-target="userAside"
                                >
                                  <em className="icon ni ni-menu-alt-r" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="nk-block">
                            <div className="nk-data data-list">
                              <div className="data-head">
                                <h6 className="overline-title">Basics</h6>
                              </div>
                              <div
                                className="data-item"
                                data-bs-toggle="modal"
                                data-bs-target="#profile-edit"
                              >
                                <div className="data-col">
                                  <span className="data-label">Full Name</span>
                                  <span className="data-value">
                                    {user.name}
                                  </span>
                                </div>
                                <div className="data-col data-col-end">
                                  <span className="data-more">
                                    <em className="icon ni ni-forward-ios" />
                                  </span>
                                </div>
                              </div>

                              <div className="data-item">
                                <div className="data-col">
                                  <span className="data-label">Email</span>
                                  <span className="data-value">
                                    {user.email}
                                  </span>
                                </div>
                                <div className="data-col data-col-end">
                                  <span className="data-more disable">
                                    <em className="icon ni ni-lock-alt" />
                                  </span>
                                </div>
                              </div>
                              <div
                                className="data-item"
                                data-bs-toggle="modal"
                                data-bs-target="#profile-edit"
                              >
                                <div className="data-col">
                                  <span className="data-label">
                                    Phone Number
                                  </span>
                                  <span className="data-value text-soft">
                                    {user.number}
                                  </span>
                                </div>
                                <div className="data-col data-col-end">
                                  <span className="data-more">
                                    <em className="icon ni ni-forward-ios" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg toggle-break-lg ${
                            sideOpen ? "content-active" : ""
                          }`}
                          data-toggle-body="true"
                          data-content="userAside"
                          data-toggle-screen="lg"
                          data-toggle-overlay="true"
                        >
                          <div className="card-inner-group" data-simplebar>
                            <div className="card-inner">
                              <div className="user-card">
                                <div className="user-avatar bg-primary">
                                  <span>{initials}</span>
                                </div>
                                <div className="user-info">
                                  <span className="lead-text">{user.name}</span>
                                  <span className="sub-text">{user.email}</span>
                                </div>
                              </div>
                            </div>

                            <div className="card-inner p-0">
                              <ul className="link-list-menu">
                                <li>
                                  <Link className="active" to="/profile">
                                    <em className="icon ni ni-user-fill-c" />
                                    <span>Personal Infomation</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/notifications">
                                    <em className="icon ni ni-bell-fill" />
                                    <span>Notifications</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/activity">
                                    <em className="icon ni ni-activity-round-fill" />
                                    <span>Account Activity</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/settings">
                                    <em className="icon ni ni-lock-alt-fill" />
                                    <span>Security Settings</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>

      {sideOpen && (
        <div
          className="toggle-overlay"
          data-target="userAside"
          onClick={toggleSide}
        />
      )}
    </div>
  );
};

export default Profile;
