import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import logo from "../../assets/whinstein_3.png";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameFieldEmpty, setNameFieldEmpty] = useState(false);
  const [emailFieldEmpty, setEmailFieldEmpty] = useState(false);
  const [numberFieldEmpty, setNumberFieldEmpty] = useState(false);
  const [passwordFieldEmpty, setPasswordFieldEmpty] = useState(false);
  const [confirmPasswordFieldEmpty, setConfirmPasswordFieldEmpty] =
    useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const navigate = useNavigate();

  const register = async (e) => {
    e.preventDefault();

    const emailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!name) {
      setNameFieldEmpty(true);
      return;
    } else {
      setNameFieldEmpty(false);
    }

    if (!email || !email.match(emailFormat)) {
      setEmailFieldEmpty(true);
      return;
    } else {
      setEmailFieldEmpty(false);
    }

    if (!number) {
      setNumberFieldEmpty(true);
      return;
    } else {
      setNumberFieldEmpty(false);
    }

    if (!password) {
      setPasswordFieldEmpty(true);
      return;
    } else {
      setPasswordFieldEmpty(false);
    }

    if (password.length < 6) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (!confirmPassword) {
      setConfirmPasswordFieldEmpty(true);
      return;
    } else {
      setConfirmPasswordFieldEmpty(false);
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://api.whinstein.com/api/auth/register",
        { name, email, number, password }
      );

      setLoading(false);
      if (data.message === "Verification email sent") {
        navigate("/verify-email");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="nk-body bg-white npc-general pg-auth">
      <div className="nk-app-root">
        <div className="nk-main ">
          <div className="nk-wrap nk-wrap-nosidebar">
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <Link to="/" className="logo-link">
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={logo}
                      alt="logo-dark"
                    />
                  </Link>
                </div>
                <div className="card card-bordered">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title text-[20px] lg:text-[24px] font-[700] mb-2">
                          Register
                        </h4>
                        <div className="nk-block-des">
                          <p>Create New Whinstein Account</p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={register}>
                      <div className="form-group">
                        <label className="form-label" htmlFor="name">
                          Full Name
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="name"
                            placeholder="Enter your full name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              setNameFieldEmpty(false);
                            }}
                          />
                          {nameFieldEmpty && (
                            <p
                              className="mt-3 text-center"
                              style={{ color: "red" }}
                            >
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="email">
                          Email address
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="email"
                            placeholder="Enter your email address"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setEmailFieldEmpty(false);
                            }}
                          />
                          {emailFieldEmpty && (
                            <p
                              className="mt-3 text-center"
                              style={{ color: "red" }}
                            >
                              Please enter a valid email address
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="number">
                          Phone Number
                        </label>
                        <div className="form-control-wrap">
                          <PhoneInput
                            placeholder="Enter your phone number"
                            className="form-control form-control-lg"
                            value={number}
                            onChange={(number) => {
                              setNumber(number);
                              setNumberFieldEmpty(false);
                            }}
                          />
                          {numberFieldEmpty && (
                            <p
                              className="mt-3 text-center"
                              style={{ color: "red" }}
                            >
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            href="#/"
                            onClick={() => setPasswordShown(!passwordShown)}
                            className="form-icon form-icon-right passcode-switch lg"
                            data-target="password"
                          >
                            {!passwordShown ? (
                              <em className="passcode-icon icon-show icon ni ni-eye" />
                            ) : (
                              <em className="passcode-icon icon-show icon ni ni-eye-off" />
                            )}
                          </a>
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="form-control form-control-lg"
                            id="password"
                            placeholder="Enter a password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setPasswordFieldEmpty(false);
                              setPasswordWeak(false);
                              setPasswordError(false);
                            }}
                          />
                          {passwordFieldEmpty && (
                            <p
                              className="mt-3 text-center"
                              style={{ color: "red" }}
                            >
                              Please enter a password
                            </p>
                          )}
                          {passwordWeak && (
                            <p
                              className="mt-3 text-center"
                              style={{ color: "red" }}
                            >
                              Password should have at least 6 characters
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="confirm-password"
                        >
                          Confirm Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            href="#/"
                            onClick={() => setPasswordShown(!passwordShown)}
                            className="form-icon form-icon-right passcode-switch lg"
                            data-target="password"
                          >
                            {!passwordShown ? (
                              <em className="passcode-icon icon-show icon ni ni-eye" />
                            ) : (
                              <em className="passcode-icon icon-show icon ni ni-eye-off" />
                            )}
                          </a>
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="form-control form-control-lg"
                            id="confirm-password"
                            placeholder="Confirm password"
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              setConfirmPasswordFieldEmpty(false);
                            }}
                          />
                          {confirmPasswordFieldEmpty && (
                            <p
                              className="mt-3 text-center"
                              style={{ color: "red" }}
                            >
                              Please enter your password again
                            </p>
                          )}
                          {passwordError && (
                            <p
                              className="mt-3 text-center"
                              style={{ color: "red" }}
                            >
                              Passwords do not match
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        {loading ? (
                          <button
                            className="btn btn-lg btn-primary btn-block"
                            disabled
                          >
                            Registering...
                          </button>
                        ) : (
                          <button className="btn btn-lg btn-primary btn-block">
                            Register
                          </button>
                        )}
                      </div>
                    </form>
                    <div className="form-note-s2 text-center pt-4">
                      {" "}
                      Already have an account?{" "}
                      <Link to="/login">
                        <strong>Sign in instead</strong>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-footer nk-auth-footer-full">
                <div className="container wide-lg">
                  <div className="row g-3">
                    <div className="col-lg-6">
                      <div className="nk-block-content text-center text-lg-left">
                        <p className="text-soft">
                          © {new Date().getFullYear()} Whinstein. All Rights
                          Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />
    </div>
  );
};

export default Register;
