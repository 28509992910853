import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";

const Wallets = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [btcAddress, setBtcAddress] = useState("");
  const [ethAddress, setEthAddress] = useState("");

  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const getDetails = async () => {
      const { data } = await axios.get("https://api.whinstein.com/api/wallet");

      setBtcAddress(data.btcAddress);
      setEthAddress(data.ethAddress);
      setPageLoading(false);
    };

    getDetails();
  }, []);

  const updateDetails = async () => {
    const token = localStorage.getItem("whinsteintkn");

    try {
      await axios.put(
        `https://api.whinstein.com/api/wallet/${user?._id}`,
        { btcAddress, ethAddress },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      navigate(0);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="modal-body modal-body-lg">
                <h5 className="title text-[20px] font-[700]">Update Wallets</h5>
                <div className="tab-content">
                  <div className="tab-pane active" id="personal">
                    <div className="row gy-4">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="full-name">
                            Bitcoin (BTC) address
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="full-name"
                            defaultValue={btcAddress}
                            onChange={(e) => setBtcAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="phone-no">
                            Ethereum (ETH) address
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="phone-no"
                            defaultValue={ethAddress}
                            onChange={(e) => setEthAddress(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <a
                              href="#/"
                              className="btn btn-lg btn-primary"
                              onClick={updateDetails}
                            >
                              Update Wallets
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="#0971fe"
      />

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />
    </div>
  );
};

export default Wallets;
