import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import logo from "../../assets/whinstein_3.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailFieldEmpty, setEmailFieldEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();

    const emailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email || !email.match(emailFormat)) {
      setEmailFieldEmpty(true);
      return;
    } else {
      setEmailFieldEmpty(false);
    }

    setLoading(true);

    try {
      await axios.post("https://api.whinstein.com/api/auth/forgotpassword", {
        email,
      });

      setLoading(false);
      toast.success("Email sent successfully");
      setEmail("");
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main">
          <div className="nk-wrap nk-wrap-nosidebar">
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <Link to="/" className="logo-link">
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={logo}
                      alt="logo-dark"
                    />
                  </Link>
                </div>
                <div className="card card-bordered">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h5 className="nk-block-title text-[20px] lg:text-[24px] font-[700] mb-2">
                          Reset Password
                        </h5>
                        <div className="nk-block-des">
                          <p>
                            If you forgot your password we’ll email you
                            instructions on how to reset your password.
                          </p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={forgotPasswordHandler}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Email
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="default-01"
                            placeholder="Enter your email address"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setEmailFieldEmpty(false);
                            }}
                          />
                          {emailFieldEmpty && (
                            <p className="mt-3 text-[red] text-center">
                              Please enter a valid email address
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        {loading ? (
                          <button
                            className="btn btn-lg btn-primary btn-block"
                            type="button"
                            disabled
                          >
                            Sending...
                          </button>
                        ) : (
                          <button className="btn btn-lg btn-primary btn-block">
                            Send Reset Link
                          </button>
                        )}
                      </div>
                    </form>
                    <div className="form-note-s2 text-center pt-4">
                      <Link to="/login">
                        <strong>Return to login</strong>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />
    </div>
  );
};

export default ForgotPassword;
