import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCj8wxXLTBb4_FR7di0_fzfFsYukuos0O4",
  authDomain: "whinstein-a3263.firebaseapp.com",
  projectId: "whinstein-a3263",
  storageBucket: "whinstein-a3263.appspot.com",
  messagingSenderId: "890429263322",
  appId: "1:890429263322:web:dd3e654bd5b589c8b4c4e3",
};

const app = initializeApp(firebaseConfig);

export default app;
