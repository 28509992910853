import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import axios from "axios";
import { useSelector } from "react-redux";
import Loading from "react-fullscreen-loading";

const KycDetails = () => {
  const [kyc, setKyc] = useState({});
  const [pageLoading, setPageLoading] = useState(true);

  const { id } = useParams();
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const token = localStorage.getItem("whinsteintkn");

    const getKycDetails = async () => {
      const { data } = await axios.get(
        `https://api.whinstein.com/api/kyc/${user?._id}/${id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setKyc(data);
      setPageLoading(false);
    };

    getKycDetails();
  }, [user?._id, id]);

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between g-3">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">
                          KYC's /{" "}
                          <strong className="text-primary small">
                            {kyc.user?.name}
                          </strong>
                        </h3>
                        <div className="nk-block-des text-soft">
                          <ul className="list-inline">
                            <li>
                              Submited At:{" "}
                              <span className="text-base">
                                {new Date(kyc?.createdAt).toLocaleString()}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="nk-block-head-content">
                        <Link
                          to="/admin/kyc"
                          className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                        >
                          <em className="icon ni ni-arrow-left" />
                          <span>Back</span>
                        </Link>
                        <Link
                          to="/admin/kyc"
                          className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                        >
                          <em className="icon ni ni-arrow-left" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="row gy-5">
                      <div className="col-lg-5">
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            <h5
                              className="nk-block-title title"
                              style={{ fontSize: "1.15rem", fontWeight: "700" }}
                            >
                              Application Info
                            </h5>
                          </div>
                        </div>
                        <div className="card card-bordered">
                          <ul className="data-list is-compact">
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Status</div>
                                <div className="data-value">
                                  {kyc?.status === "Approved" && (
                                    <span className="badge badge-dim badge-sm bg-outline-success">
                                      Approved
                                    </span>
                                  )}
                                  {kyc?.status === "Pending" && (
                                    <span className="badge badge-dim badge-sm bg-outline-info">
                                      Pending
                                    </span>
                                  )}
                                  {kyc?.status === "Rejected" && (
                                    <span className="badge badge-dim badge-sm bg-outline-danger">
                                      Rejected
                                    </span>
                                  )}
                                </div>
                              </div>
                            </li>
                            {kyc?.checkedBy && (
                              <>
                                <li className="data-item">
                                  <div className="data-col">
                                    <div className="data-label">Checked by</div>
                                    <div className="data-value">
                                      <div className="user-card">
                                        <div className="user-avatar user-avatar-xs bg-orange-dim">
                                          <span>
                                            {kyc.checkedBy?.name
                                              ?.match(/(^\S\S?|\b\S)?/g)
                                              .join("")
                                              .match(/(^\S|\S$)?/g)
                                              .join("")
                                              .toUpperCase()}
                                          </span>
                                        </div>
                                        <div className="user-name">
                                          <span className="tb-lead">
                                            {kyc.checkedBy?.name}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            <h5
                              className="nk-block-title title"
                              style={{ fontSize: "1.15rem", fontWeight: "700" }}
                            >
                              Uploaded Document
                            </h5>
                          </div>
                        </div>
                        <div className="card card-bordered">
                          <ul className="data-list is-compact">
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Document Type</div>
                                <div className="data-value">
                                  <a
                                    href={kyc?.document}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {kyc?.id}
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            <h5
                              className="nk-block-title title"
                              style={{ fontSize: "1.15rem", fontWeight: "700" }}
                            >
                              Applicant Information
                            </h5>
                            <p>
                              Basic info like name, phone, address, country etc.
                            </p>
                          </div>
                        </div>
                        <div className="card card-bordered">
                          <ul className="data-list is-compact">
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">First Name</div>
                                <div className="data-value">
                                  {kyc?.firstName}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Last Name</div>
                                <div className="data-value">
                                  {kyc?.lastName}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Email Address</div>
                                <div className="data-value">{kyc?.email}</div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Phone Number</div>
                                <div className="data-value">{kyc?.number}</div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Date of Birth</div>
                                <div className="data-value">
                                  {new Date(kyc?.dob).toLocaleDateString()}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Full Address</div>
                                <div className="data-value">
                                  {kyc?.addressOne}, {kyc?.city}, {kyc?.state},{" "}
                                  {kyc?.zipCode}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Nationality</div>
                                <div className="data-value">
                                  {kyc?.nationality}
                                </div>
                              </div>
                            </li>
                            {kyc?.addressTwo && (
                              <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">
                                    Secondary Address
                                  </div>
                                  <div className="data-value">
                                    {kyc?.addressTwo}, {kyc?.city}, {kyc?.state}
                                    , {kyc?.zipCode}
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="#0971fe"
      />
    </div>
  );
};

export default KycDetails;
