import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserDetails = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [userSidebarOpen, setUserSidebarOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [tabSelected, setTabSelected] = useState("profile");
  const [creditModalOpen, setCreditModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [balance, setBalance] = useState("");
  const [btc, setBtc] = useState("");
  const [eth, setEth] = useState("");
  const [loading, setLoading] = useState(false);
  const [profit, setProfit] = useState("");
  const [profitSelected, setProfitSelected] = useState("btc");
  const [btcRate, setBtcRate] = useState("");
  const [ethRate, setEthRate] = useState("");

  const { id } = useParams();
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  const toggleUserSidebar = () => {
    setUserSidebarOpen(!userSidebarOpen);
  };

  const selectProfile = () => {
    setTabSelected("profile");
  };

  const selectTransactions = () => {
    setTabSelected("transactions");
  };

  const toggleOptions = () => {
    setOptionsOpen(!optionsOpen);
  };

  useEffect(() => {
    const getPrice = async () => {
      const { data } = await axios.get(
        "https://rest.coinapi.io/v1/exchangerate/BTC/USD",
        {
          headers: {
            "X-CoinAPI-Key": "EB9C18A1-0064-450D-BEA1-071706C3AEA5",
          },
        }
      );

      setBtcRate(Math.round(data.rate));
    };

    getPrice();
  }, []);

  useEffect(() => {
    const getPrice = async () => {
      const { data } = await axios.get(
        "https://rest.coinapi.io/v1/exchangerate/ETH/USD",
        {
          headers: {
            "X-CoinAPI-Key": "EB9C18A1-0064-450D-BEA1-071706C3AEA5",
          },
        }
      );

      setEthRate(Math.round(data.rate));
    };

    getPrice();
  }, []);

  const editFigures = async () => {
    const token = localStorage.getItem("whinsteintkn");
    setLoading(true);

    try {
      await axios.put(
        `https://api.whinstein.com/api/transaction/edit`,
        { id: userDetails?._id, balance: +balance, btc: +btc, eth: +eth },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const creditProfit = async () => {
    const token = localStorage.getItem("whinsteintkn");
    setLoading(true);

    try {
      await axios.put(
        `https://api.whinstein.com/api/transaction/credit`,
        {
          id: userDetails?._id,
          profit: +profit,
          profitSelected,
          btc: +(profit / btcRate)?.toFixed(6),
          eth: +(profit / ethRate)?.toFixed(6),
          user: userDetails,
          transactionId: new Date().getTime().toString(),
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("whinsteintkn");

    const getUserDetails = async () => {
      const { data } = await axios.get(
        `https://api.whinstein.com/api/user/${user?._id}/${id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setUserDetails(data);
      setBalance(data.balance);
      setBtc(data.btc);
      setEth(data.eth);
      setPageLoading(false);
    };

    getUserDetails();
  }, [user?._id, id]);

  useEffect(() => {
    const token = localStorage.getItem("whinsteintkn");

    const getTransactions = async () => {
      const { data } = await axios.get(
        `https://api.whinstein.com/api/transaction/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
    };

    getTransactions();
  }, [user?._id]);

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between g-3">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">
                          Users /{" "}
                          <strong className="text-primary small">
                            {userDetails?.name}
                          </strong>
                        </h3>
                      </div>

                      <div className="nk-block-head-content">
                        <div className="toggle-wrap nk-block-tools-toggle">
                          <a
                            href="#/"
                            onClick={toggleOptions}
                            className="btn btn-icon btn-trigger toggle-expand me-n1"
                            data-target="pageMenu"
                          >
                            <em className="icon ni ni-menu-alt-r" />
                          </a>
                          <div
                            className={`toggle-expand-content ${
                              optionsOpen ? "expanded" : ""
                            }`}
                            data-content="pageMenu"
                          >
                            <ul className="nk-block-tools g-3">
                              <li className="nk-block-tools-opt">
                                <div className="drodown">
                                  <a
                                    href="#/"
                                    className="dropdown-toggle btn btn-icon btn-primary"
                                    data-bs-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-plus" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={() =>
                                            setCreditModalOpen(true)
                                          }
                                        >
                                          <span>Credit Profit</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#/"
                                          onClick={() => setEditModalOpen(true)}
                                        >
                                          <span>Edit figures</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="card card-bordered">
                      <div className="card-aside-wrap">
                        <div className="card-content">
                          <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  tabSelected === "profile" ? "active" : ""
                                }`}
                                href="#/"
                                onClick={selectProfile}
                              >
                                <em className="icon ni ni-user-circle" />
                                <span>Personal</span>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  tabSelected === "transactions" ? "active" : ""
                                }`}
                                href="#/"
                                onClick={selectTransactions}
                              >
                                <em className="icon ni ni-repeat" />
                                <span>Transactions</span>
                              </a>
                            </li>
                            <li className="nav-item nav-item-trigger d-xxl-none">
                              <a
                                href="#/"
                                onClick={toggleUserSidebar}
                                className="toggle btn btn-icon btn-trigger"
                                data-target="userAside"
                              >
                                <em className="icon ni ni-user-list-fill" />
                              </a>
                            </li>
                          </ul>
                          {tabSelected === "profile" && (
                            <div className="card-inner">
                              <div className="nk-block">
                                <div className="nk-block-head">
                                  <h5 className="title">
                                    Personal Information
                                  </h5>
                                </div>
                                <div className="profile-ud-list">
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Full Name
                                      </span>
                                      <span className="profile-ud-value">
                                        {userDetails?.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Mobile Number
                                      </span>
                                      <span className="profile-ud-value">
                                        {userDetails?.number}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Email Address
                                      </span>
                                      <span className="profile-ud-value">
                                        {userDetails?.email}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="nk-block">
                                <div className="nk-block-head nk-block-head-line">
                                  <h6 className="title overline-title text-base">
                                    Additional Information
                                  </h6>
                                </div>
                                <div className="profile-ud-list">
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Joining Date
                                      </span>
                                      <span className="profile-ud-value">
                                        {new Date(
                                          userDetails?.createdAt
                                        ).toLocaleDateString()}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profile-ud-item">
                                    <div className="profile-ud wider">
                                      <span className="profile-ud-label">
                                        Reg Method
                                      </span>
                                      <span className="profile-ud-value">
                                        Email
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {tabSelected === "transactions" && (
                            <div className="card-inner p-0">
                              <div className="nk-tb-list nk-tb-tnx">
                                <div className="nk-tb-item nk-tb-head">
                                  <div className="nk-tb-col">
                                    <span>Details</span>
                                  </div>
                                  <div className="nk-tb-col tb-col-xxl">
                                    <span>Source</span>
                                  </div>
                                  <div className="nk-tb-col tb-col-lg">
                                    <span>Order</span>
                                  </div>
                                  <div className="nk-tb-col text-end">
                                    <span>Amount</span>
                                  </div>
                                  {/* <div className="nk-tb-col text-end tb-col-sm">
                                <span>User</span>
                              </div> */}
                                  <div className="nk-tb-col nk-tb-col-status">
                                    <span className="sub-text d-none d-md-block">
                                      Status
                                    </span>
                                  </div>
                                  <div className="nk-tb-col nk-tb-col-tools" />
                                </div>
                                {transactions?.map((transaction) => (
                                  <div
                                    className="nk-tb-item"
                                    key={transaction._id}
                                  >
                                    <div className="nk-tb-col">
                                      <div className="nk-tnx-type">
                                        {transaction.status === "Approved" && (
                                          <div className="nk-tnx-type-icon bg-success-dim text-success">
                                            <em className="icon ni ni-arrow-up-right" />
                                          </div>
                                        )}
                                        {transaction.status === "Pending" && (
                                          <div className="nk-tnx-type-icon bg-info-dim text-info">
                                            <em className="icon ni ni-arrow-up-right" />
                                          </div>
                                        )}
                                        {transaction.status === "Rejected" && (
                                          <div className="nk-tnx-type-icon bg-danger-dim text-danger">
                                            <em className="icon ni ni-arrow-up-right" />
                                          </div>
                                        )}
                                        <div className="nk-tnx-type-text">
                                          {transaction.type === "Deposit" && (
                                            <span className="tb-lead">
                                              Deposited Funds
                                            </span>
                                          )}
                                          {transaction.type ===
                                            "Withdrawal" && (
                                            <span className="tb-lead">
                                              Withdrawal Funds
                                            </span>
                                          )}
                                          {transaction.type === "Credited" && (
                                            <span className="tb-lead">
                                              Credited Profits
                                            </span>
                                          )}
                                          <span className="tb-date">
                                            {new Date(
                                              transaction.createdAt
                                            ).toLocaleString()}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="nk-tb-col tb-col-lg">
                                      <span className="tb-lead-sub">
                                        {transaction.transactionId}
                                      </span>
                                      {transaction.status === "Approved" && (
                                        <span className="badge badge-dot bg-success">
                                          {transaction?.type
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            transaction?.type?.slice(1)}
                                        </span>
                                      )}
                                      {transaction.status === "Pending" && (
                                        <span className="badge badge-dot bg-info">
                                          {transaction?.type
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            transaction?.type?.slice(1)}
                                        </span>
                                      )}
                                      {transaction.status === "Rejected" && (
                                        <span className="badge badge-dot bg-danger">
                                          {transaction?.type
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            transaction?.type?.slice(1)}
                                        </span>
                                      )}
                                    </div>
                                    <div className="nk-tb-col text-end">
                                      <span className="tb-amount">
                                        {transaction.usd}
                                        <span> USD</span>
                                      </span>
                                      {transaction.btc ? (
                                        <span className="tb-amount-sm">
                                          {transaction.btc} BTC
                                        </span>
                                      ) : (
                                        <span className="tb-amount-sm">
                                          {transaction.eth} ETH
                                        </span>
                                      )}
                                    </div>
                                    {/* <div className="nk-tb-col text-end tb-col-sm">
                                  <span className="tb-amount">
                                    {transaction.user.name}
                                  </span>
                                  <span className="tb-amount-sm">
                                    {transaction.user.email}
                                  </span>
                                </div> */}
                                    {transaction.status === "Approved" && (
                                      <div className="nk-tb-col nk-tb-col-status">
                                        <div className="dot dot-success d-md-none" />
                                        <span className="badge badge-sm badge-dim bg-outline-success d-none d-md-inline-flex">
                                          Approved
                                        </span>
                                      </div>
                                    )}
                                    {transaction.status === "Pending" && (
                                      <div className="nk-tb-col nk-tb-col-status">
                                        <div className="dot dot-info d-md-none" />
                                        <span className="badge badge-sm badge-dim bg-outline-info d-none d-md-inline-flex">
                                          Pending
                                        </span>
                                      </div>
                                    )}
                                    {transaction.status === "Rejected" && (
                                      <div className="nk-tb-col nk-tb-col-status">
                                        <div className="dot dot-danger d-md-none" />
                                        <span className="badge badge-sm badge-dim bg-outline-danger d-none d-md-inline-flex">
                                          Rejected
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                ))}
                                {transactions?.length === 0 && (
                                  <p
                                    style={{
                                      position: "relative",
                                      left: "50%",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    No records
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className={`card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl toggle-screen-xxl ${
                            userSidebarOpen ? "content-active" : ""
                          }`}
                          data-content="userAside"
                          data-toggle-screen="xxl"
                          data-toggle-overlay="true"
                          data-toggle-body="true"
                        >
                          <div className="card-inner-group" data-simplebar>
                            <div className="card-inner">
                              <div className="user-card user-card-s2">
                                <div className="user-avatar lg bg-primary">
                                  <span>
                                    {userDetails?.name
                                      ?.match(/(^\S\S?|\b\S)?/g)
                                      .join("")
                                      .match(/(^\S|\S$)?/g)
                                      .join("")
                                      .toUpperCase()}
                                  </span>
                                </div>
                                <div className="user-info">
                                  <h5>{userDetails?.name}</h5>
                                  <span className="sub-text">
                                    {userDetails?.email}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="card-inner">
                              <div className="overline-title-alt mb-2">
                                Balance
                              </div>
                              <div className="profile-balance">
                                <div className="profile-balance-group gx-4">
                                  <div className="profile-balance-sub">
                                    <div className="profile-balance-amount">
                                      <div className="number">
                                        {userDetails?.balance}{" "}
                                        <small className="currency currency-usd">
                                          USD
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="profile-balance-group gx-4">
                                  <div className="profile-balance-sub">
                                    <div className="profile-balance-amount">
                                      <div className="number">
                                        {userDetails?.btc}{" "}
                                        <small className="currency currency-usd">
                                          BTC
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="profile-balance-group gx-4">
                                  <div className="profile-balance-sub">
                                    <div className="profile-balance-amount">
                                      <div className="number">
                                        {userDetails?.eth}{" "}
                                        <small className="currency currency-usd">
                                          ETH
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-inner">
                              <h6 className="overline-title-alt mb-2">
                                Additional
                              </h6>
                              <div className="row g-3">
                                <div className="col-6">
                                  <span className="sub-text">KYC Status:</span>
                                  {userDetails?.kycStatus === "Approved" && (
                                    <span className="lead-text text-success">
                                      {userDetails?.kycStatus}
                                    </span>
                                  )}
                                  {userDetails?.kycStatus === "Rejected" && (
                                    <span className="lead-text text-danger">
                                      {userDetails?.kycStatus}
                                    </span>
                                  )}
                                  {(userDetails?.kycStatus === "Unverified" ||
                                    userDetails?.kycStatus === "Pending") && (
                                    <span className="lead-text text-warning">
                                      {userDetails?.kycStatus}
                                    </span>
                                  )}
                                </div>
                                <div className="col-6">
                                  <span className="sub-text">Joined on:</span>
                                  <span>
                                    {new Date(
                                      userDetails?.createdAt
                                    ).toDateString()}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="card-inner" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {userSidebarOpen && (
        <div
          className="toggle-overlay"
          data-target="userAside"
          onClick={toggleUserSidebar}
        />
      )}

      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="#0971fe"
      />

      {editModalOpen && (
        <>
          <div className="modal-backdrop fade show" />
          <div
            className="modal fade show"
            role="dialog"
            id="profile-edit"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <button
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setEditModalOpen(false)}
                >
                  <em className="icon ni ni-cross-sm" />
                </button>
                <div className="modal-body modal-body-lg">
                  <h5 className="title text-[18px] font-[700]">Edit figures</h5>

                  <div className="tab-content">
                    <div className="tab-pane active" id="personal">
                      <div className="row gy-4">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="balance">
                              Balance
                            </label>
                            <input
                              type="number"
                              className="form-control form-control-lg"
                              id="balance"
                              defaultValue={balance}
                              onChange={(e) => setBalance(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="ethereum">
                              Ethereum
                            </label>
                            <input
                              type="number"
                              className="form-control form-control-lg"
                              id="ethereum"
                              defaultValue={eth}
                              onChange={(e) => setEth(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="bitcoin">
                              Bitcoin
                            </label>
                            <input
                              type="number"
                              className="form-control form-control-lg"
                              id="bitcoin"
                              defaultValue={btc}
                              onChange={(e) => setBtc(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              {loading ? (
                                <button
                                  data-bs-dismiss="modal"
                                  className="btn btn-lg btn-primary"
                                  disabled
                                >
                                  Updating...
                                </button>
                              ) : (
                                <button
                                  onClick={editFigures}
                                  data-bs-dismiss="modal"
                                  className="btn btn-lg btn-primary"
                                >
                                  Update Profile
                                </button>
                              )}
                            </li>
                            <li>
                              <a
                                href="#/"
                                onClick={() => setEditModalOpen(false)}
                                data-bs-dismiss="modal"
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {creditModalOpen && (
        <>
          <div className="modal-backdrop fade show" />
          <div
            className="modal fade show"
            role="dialog"
            id="profile-edit"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <button
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => setCreditModalOpen(false)}
                >
                  <em className="icon ni ni-cross-sm" />
                </button>
                <div className="modal-body modal-body-lg">
                  <h5 className="title text-[18px] font-[700]">
                    Credit Profit
                  </h5>

                  <div className="tab-content">
                    <div className="tab-pane active" id="personal">
                      <div className="row gy-4">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="balance">
                              Amount
                            </label>
                            <input
                              type="number"
                              className="form-control form-control-lg"
                              id="balance"
                              value={profit}
                              placeholder="Enter profit"
                              onChange={(e) => setProfit(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="dropdown buysell-cc-dropdown">
                          <a
                            href="#/"
                            className="buysell-cc-choosen dropdown-indicator"
                            data-bs-toggle="dropdown"
                          >
                            {profitSelected === "btc" ? (
                              <div className="coin-item coin-btc">
                                <div className="coin-icon">
                                  <em className="icon ni ni-sign-btc-alt" />
                                </div>
                                <div className="coin-info">
                                  <span className="coin-name">
                                    Bitcoin (BTC)
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="coin-item coin-eth">
                                <div className="coin-icon">
                                  <em className="icon ni ni-sign-eth-alt" />
                                </div>
                                <div className="coin-info">
                                  <span className="coin-name">
                                    Ethereum (ETH)
                                  </span>
                                </div>
                              </div>
                            )}
                          </a>
                          <div className="dropdown-menu dropdown-menu-auto dropdown-menu-mxh">
                            <ul className="buysell-cc-list">
                              <li
                                className="buysell-cc-item"
                                onClick={() => setProfitSelected("btc")}
                              >
                                <a
                                  href="#/"
                                  className="buysell-cc-opt"
                                  data-currency="btc"
                                >
                                  <div className="coin-item coin-btc">
                                    <div className="coin-icon">
                                      <em className="icon ni ni-sign-btc-alt" />
                                    </div>
                                    <div className="coin-info">
                                      <span className="coin-name">
                                        Bitcoin (BTC)
                                      </span>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li
                                className="buysell-cc-item"
                                onClick={() => setProfitSelected("eth")}
                              >
                                <a
                                  href="#/"
                                  className="buysell-cc-opt"
                                  data-currency="eth"
                                >
                                  <div className="coin-item coin-eth">
                                    <div className="coin-icon">
                                      <em className="icon ni ni-sign-eth-alt" />
                                    </div>
                                    <div className="coin-info">
                                      <span className="coin-name">
                                        Ethereum (ETH)
                                      </span>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              {loading ? (
                                <button
                                  data-bs-dismiss="modal"
                                  className="btn btn-lg btn-primary"
                                  disabled
                                >
                                  Crediting...
                                </button>
                              ) : (
                                <button
                                  onClick={creditProfit}
                                  data-bs-dismiss="modal"
                                  className="btn btn-lg btn-primary"
                                >
                                  Credit Profit
                                </button>
                              )}
                            </li>
                            <li>
                              <a
                                href="#/"
                                onClick={() => setCreditModalOpen(false)}
                                data-bs-dismiss="modal"
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />
    </div>
  );
};

export default UserDetails;
