import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Activity = () => {
  const [sideOpen, setSideOpen] = useState(false);
  const [activity, setActivity] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  const toggleSide = () => {
    setSideOpen(!sideOpen);
  };

  const deleteRecord = async (id) => {
    const token = localStorage.getItem("whinsteintkn");

    try {
      await axios.delete(
        `https://api.whinstein.com/api/activity/${user?._id}/${id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      navigate(0);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("whinsteintkn");

    const getActivity = async () => {
      const { data } = await axios.get(
        `https://api.whinstein.com/api/activity/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setActivity(data);
      setPageLoading(false);
    };

    getActivity();
  }, [user?._id]);

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="nk-block">
                    <div className="card card-bordered">
                      <div className="card-aside-wrap">
                        <div className="card-inner card-inner-lg">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between">
                              <div className="nk-block-head-content">
                                <h4
                                  className="nk-block-title"
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    marginBottom: "8px",
                                  }}
                                >
                                  Login Activity
                                </h4>
                                <div className="nk-block-des">
                                  <p>
                                    Here is your last 20 login activities log.
                                  </p>
                                </div>
                              </div>
                              <div className="nk-block-head-content align-self-start d-lg-none">
                                <a
                                  href="#/"
                                  onClick={toggleSide}
                                  className="toggle btn btn-icon btn-trigger mt-n1"
                                  data-target="userAside"
                                >
                                  <em className="icon ni ni-menu-alt-r" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="nk-block card card-bordered">
                            {activity?.length > 0 ? (
                              <table className="table table-ulogs">
                                <thead className="table-light">
                                  <tr>
                                    <th className="tb-col-os">
                                      <span className="overline-title">
                                        Browser
                                        <span className="d-sm-none">/ IP</span>
                                      </span>
                                    </th>
                                    <th className="tb-col-ip">
                                      <span className="overline-title">IP</span>
                                    </th>
                                    <th className="tb-col-time">
                                      <span className="overline-title">
                                        Time
                                      </span>
                                    </th>
                                    <th className="tb-col-action">
                                      <span className="overline-title">
                                        &nbsp;
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {activity?.map((record) => (
                                    <tr key={record._id}>
                                      <td className="tb-col-os">
                                        {record.browser} on {record.os}
                                      </td>
                                      <td className="tb-col-ip">
                                        <span className="sub-text">
                                          {record.ip}
                                        </span>
                                      </td>
                                      <td className="tb-col-time">
                                        <span className="sub-text">
                                          {new Date(
                                            record?.createdAt
                                          ).toDateString()}
                                          <span className="d-none d-sm-inline-block">
                                            {new Date(
                                              record?.createdAt
                                            ).toLocaleTimeString()}
                                          </span>
                                        </span>
                                      </td>
                                      <td className="tb-col-action">
                                        <button
                                          className="link-cross me-sm-n1"
                                          onClick={() =>
                                            deleteRecord(record._id)
                                          }
                                        >
                                          <em className="icon ni ni-cross" />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <p
                                style={{ textAlign: "center", padding: "10px" }}
                              >
                                No saved activity
                              </p>
                            )}
                          </div>
                        </div>
                        <div
                          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg toggle-break-lg ${
                            sideOpen ? "content-active" : ""
                          }`}
                          data-toggle-body="true"
                          data-content="userAside"
                          data-toggle-screen="lg"
                          data-toggle-overlay="true"
                        >
                          <div className="card-inner-group">
                            <div className="card-inner">
                              <div className="user-card">
                                <div className="user-avatar bg-primary">
                                  <span>
                                    {user?.name
                                      ?.match(/(^\S\S?|\b\S)?/g)
                                      .join("")
                                      .match(/(^\S|\S$)?/g)
                                      .join("")
                                      .toUpperCase()}
                                  </span>
                                </div>
                                <div className="user-info">
                                  <span className="lead-text">
                                    {user?.name}
                                  </span>
                                  <span className="sub-text">
                                    {user?.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="card-inner p-0">
                              <ul className="link-list-menu">
                                <li>
                                  <Link to="/profile">
                                    <em className="icon ni ni-user-fill-c" />
                                    <span>Personal Infomation</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/notifications">
                                    <em className="icon ni ni-bell-fill" />
                                    <span>Notifications</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link className="active" to="/activity">
                                    <em className="icon ni ni-activity-round-fill" />
                                    <span>Account Activity</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/settings">
                                    <em className="icon ni ni-lock-alt-fill" />
                                    <span>Security Settings</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {sideOpen && (
        <div
          className="toggle-overlay"
          data-target="userAside"
          onClick={toggleSide}
        />
      )}

      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="#0971fe"
      />

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />
    </div>
  );
};

export default Activity;
