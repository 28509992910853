import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { useSelector } from "react-redux";
import Loading from "react-fullscreen-loading";
import axios from "axios";

const Dashboard = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);

  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const token = localStorage.getItem("whinsteintkn");

    const getTransactions = async () => {
      const { data } = await axios.get(
        `https://api.whinstein.com/api/transaction/${user?._id}/recent`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setPageLoading(false);
    };

    getTransactions();
  }, [user?._id]);

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="nk-block-head">
                    <div className="nk-block-head-sub">
                      <span>Welcome!</span>
                    </div>
                    <div className="nk-block-between-md g-4">
                      <div className="nk-block-head-content">
                        <h2 className="nk-block-title fw-normal text-[28px] font-[400] lg:text-[40px]">
                          {user.name}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="row gy-gs">
                      <div className="col-lg-5 col-xl-4">
                        <div className="nk-block">
                          <div className="nk-block-head-xs">
                            <div className="nk-block-head-content">
                              <h5 className="nk-block-title title text-[18px] font-[700] lg:text-[20px]">
                                Overview
                              </h5>
                            </div>
                          </div>
                          <div className="nk-block">
                            <div className="card card-bordered text-light is-dark h-100">
                              <div className="card-inner">
                                <div className="nk-wg7">
                                  <div className="nk-wg7-stats">
                                    <div className="nk-wg7-title">
                                      Available balance in USD
                                    </div>
                                    <div className="number-lg amount">
                                      {user?.balance}
                                    </div>
                                  </div>
                                  <div className="nk-wg7-stats-group">
                                    <div className="nk-wg7-stats w-50">
                                      <div className="nk-wg7-title">BTC</div>
                                      <div
                                        className="number-lg"
                                        style={{ fontSize: "20px" }}
                                      >
                                        {user?.btc}
                                      </div>
                                    </div>
                                    <div className="nk-wg7-stats w-50">
                                      <div className="nk-wg7-title">ETH</div>
                                      <div
                                        className="number-lg"
                                        style={{ fontSize: "20px" }}
                                      >
                                        {user?.eth}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 col-xl-8">
                        <div className="nk-block">
                          <div className="nk-block-head-xs">
                            <div className="nk-block-between-md g-2">
                              <div className="nk-block-head-content">
                                <h5 className="nk-block-title title text-[18px] font-[700] lg:text-[20px]">
                                  Digital Wallets
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="row g-2">
                            <div className="col-sm-4">
                              <div className="card bg-light">
                                <div className="nk-wgw sm">
                                  <a className="nk-wgw-inner" href="#/">
                                    <div className="nk-wgw-name">
                                      <div className="nk-wgw-icon">
                                        <em className="icon ni ni-sign-btc" />
                                      </div>
                                      <h5 className="nk-wgw-title title">
                                        Bitcoin Wallet
                                      </h5>
                                    </div>
                                    <div className="nk-wgw-balance">
                                      <div className="amount">
                                        {user?.btc}
                                        <span className="currency currency-btc">
                                          BTC
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="card bg-light">
                                <div className="nk-wgw sm">
                                  <a className="nk-wgw-inner" href="#/">
                                    <div className="nk-wgw-name">
                                      <div className="nk-wgw-icon">
                                        <em className="icon ni ni-sign-eth" />
                                      </div>
                                      <h5 className="nk-wgw-title title">
                                        Ethereum Wallet
                                      </h5>
                                    </div>
                                    <div className="nk-wgw-balance">
                                      <div className="amount">
                                        {user?.eth}
                                        <span className="currency currency-eth">
                                          ETH
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block nk-block-lg">
                    <div className="row gy-gs">
                      <div className="col-md-6">
                        <div className="card-head">
                          <div className="card-title  mb-0">
                            <h5 className="title text-[18px] font-[700] lg:text-[20px]">
                              Recent Transactions
                            </h5>
                          </div>
                        </div>
                        {transactions?.length > 0 ? (
                          <div className="tranx-list card card-bordered">
                            {transactions?.map((transaction) => (
                              <div className="tranx-item" key={transaction._id}>
                                <div className="tranx-col">
                                  <div className="tranx-info">
                                    <div className="tranx-data">
                                      {transaction.type === "Deposit" && (
                                        <div className="tranx-label">
                                          Deposit{" "}
                                          {transaction.btc
                                            ? "Bitcoin"
                                            : "Ethereum"}{" "}
                                          {transaction.btc ? (
                                            <em className="tranx-icon sm icon ni ni-sign-btc" />
                                          ) : (
                                            <em className="tranx-icon sm icon ni ni-sign-eth" />
                                          )}
                                        </div>
                                      )}
                                      {transaction.type === "Withdrawal" && (
                                        <div className="tranx-label">
                                          Withdraw{" "}
                                          {transaction.btc
                                            ? "Bitcoin"
                                            : "Ethereum"}{" "}
                                          {transaction.btc ? (
                                            <em className="tranx-icon sm icon ni ni-sign-btc" />
                                          ) : (
                                            <em className="tranx-icon sm icon ni ni-sign-eth" />
                                          )}
                                        </div>
                                      )}
                                      {transaction.type === "Credited" && (
                                        <div className="tranx-label">
                                          Credited Profits
                                        </div>
                                      )}
                                      <div className="tranx-date">
                                        {new Date(
                                          transaction.createdAt
                                        ).toLocaleString()}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="tranx-col">
                                  <div className="tranx-amount">
                                    <div className="number">
                                      {transaction.usd}{" "}
                                      <span className="currency currency-btc">
                                        USD
                                      </span>
                                    </div>
                                    <div className="number-sm">
                                      {transaction.btc
                                        ? transaction.btc
                                        : transaction.eth}{" "}
                                      {transaction.btc ? (
                                        <span className="currency currency-usd">
                                          USD
                                        </span>
                                      ) : (
                                        <span className="currency currency-usd">
                                          ETH
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "20px",
                              fontWeight: "500",
                              marginTop: "40px",
                              marginBottom: "40px",
                            }}
                          >
                            No records
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="nk-block">
                    <div className="card card-bordered">
                      <div className="card-inner card-inner-lg">
                        <div className="align-center flex-wrap flex-md-nowrap g-4">
                          <div className="nk-block-image w-120px flex-shrink-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 120 118"
                            >
                              <path
                                d="M8.916,94.745C-.318,79.153-2.164,58.569,2.382,40.578,7.155,21.69,19.045,9.451,35.162,4.32,46.609.676,58.716.331,70.456,1.845,84.683,3.68,99.57,8.694,108.892,21.408c10.03,13.679,12.071,34.71,10.747,52.054-1.173,15.359-7.441,27.489-19.231,34.494-10.689,6.351-22.92,8.733-34.715,10.331-16.181,2.192-34.195-.336-47.6-12.281A47.243,47.243,0,0,1,8.916,94.745Z"
                                transform="translate(0 -1)"
                                fill="#f6faff"
                              />
                              <rect
                                x={18}
                                y={32}
                                width={84}
                                height={50}
                                rx={4}
                                ry={4}
                                fill="#fff"
                              />
                              <rect
                                x={26}
                                y={44}
                                width={20}
                                height={12}
                                rx={1}
                                ry={1}
                                fill="#e5effe"
                              />
                              <rect
                                x={50}
                                y={44}
                                width={20}
                                height={12}
                                rx={1}
                                ry={1}
                                fill="#e5effe"
                              />
                              <rect
                                x={74}
                                y={44}
                                width={20}
                                height={12}
                                rx={1}
                                ry={1}
                                fill="#e5effe"
                              />
                              <rect
                                x={38}
                                y={60}
                                width={20}
                                height={12}
                                rx={1}
                                ry={1}
                                fill="#e5effe"
                              />
                              <rect
                                x={62}
                                y={60}
                                width={20}
                                height={12}
                                rx={1}
                                ry={1}
                                fill="#e5effe"
                              />
                              <path
                                d="M98,32H22a5.006,5.006,0,0,0-5,5V79a5.006,5.006,0,0,0,5,5H52v8H45a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H73a2,2,0,0,0,2-2V94a2,2,0,0,0-2-2H66V84H98a5.006,5.006,0,0,0,5-5V37A5.006,5.006,0,0,0,98,32ZM73,94v4H45V94Zm-9-2H54V84H64Zm37-13a3,3,0,0,1-3,3H22a3,3,0,0,1-3-3V37a3,3,0,0,1,3-3H98a3,3,0,0,1,3,3Z"
                                transform="translate(0 -1)"
                                fill="#798bff"
                              />
                              <path
                                d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
                                transform="translate(0 -1)"
                                fill="#6576ff"
                              />
                              <path
                                d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
                                transform="translate(0 -1)"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit={10}
                                strokeWidth={2}
                              />
                              <line
                                x1={40}
                                y1={22}
                                x2={57}
                                y2={22}
                                fill="none"
                                stroke="#fffffe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                              />
                              <line
                                x1={40}
                                y1={27}
                                x2={57}
                                y2={27}
                                fill="none"
                                stroke="#fffffe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                              />
                              <line
                                x1={40}
                                y1={32}
                                x2={50}
                                y2={32}
                                fill="none"
                                stroke="#fffffe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                              />
                              <line
                                x1="30.5"
                                y1="87.5"
                                x2="30.5"
                                y2="91.5"
                                fill="none"
                                stroke="#9cabff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <line
                                x1="28.5"
                                y1="89.5"
                                x2="32.5"
                                y2="89.5"
                                fill="none"
                                stroke="#9cabff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <line
                                x1="79.5"
                                y1="22.5"
                                x2="79.5"
                                y2="26.5"
                                fill="none"
                                stroke="#9cabff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <line
                                x1="77.5"
                                y1="24.5"
                                x2="81.5"
                                y2="24.5"
                                fill="none"
                                stroke="#9cabff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <circle
                                cx="90.5"
                                cy="97.5"
                                r={3}
                                fill="none"
                                stroke="#9cabff"
                                strokeMiterlimit={10}
                              />
                              <circle
                                cx={24}
                                cy={23}
                                r="2.5"
                                fill="none"
                                stroke="#9cabff"
                                strokeMiterlimit={10}
                              />
                            </svg>
                          </div>
                          <div className="nk-block-content">
                            <div className="nk-block-content-head px-lg-4">
                              <h5 className="text-[18px] font-[700] lg:text-[20px]">
                                We’re here to help you!
                              </h5>
                              <p className="text-soft">
                                Ask a question or file a support ticket, manage
                                request, report an issue. Our support team will
                                get back to you via email.
                              </p>
                            </div>
                          </div>
                          <div className="nk-block-content flex-shrink-0">
                            <a
                              href="mailto:support@whinstein.com"
                              className="btn btn-lg btn-outline-primary"
                            >
                              Get Support Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="#0971fe"
      />
    </div>
  );
};

export default Dashboard;
