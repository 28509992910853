import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import Loading from "react-fullscreen-loading";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserTransactions = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [displayedTransactions, setDisplayedTransactions] = useState(10);
  const [recordsToDisplay, setRecordsToDisplay] = useState(
    displayedTransactions
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [transactionToView, setTransactionToView] = useState({});

  const loadMoreRecords = () => {
    setRecordsToDisplay(recordsToDisplay + displayedTransactions);
  };

  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("whinsteintkn");

    const getTransactions = async () => {
      const { data } = await axios.get(
        `https://api.whinstein.com/api/transaction/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setPageLoading(false);
    };

    getTransactions();
  }, [user?._id]);

  const deleteTransaction = async () => {
    const token = localStorage.getItem("whinsteintkn");
    setLoading(true);

    try {
      await axios.delete(
        `https://api.whinstein.com/api/transaction/delete/${idToDelete}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between g-3">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">
                          Crypto Transactions
                        </h3>
                        <div className="nk-block-des text-soft">
                          <p>
                            You have total {transactions?.length} transactions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title" />
                            <div className="card-tools me-n1">
                              <ul className="btn-toolbar gx-1">
                                {/* <li>
                                  <a
                                    href="#/"
                                    className="search-toggle toggle-search btn btn-icon"
                                    data-target="search"
                                  >
                                    <em className="icon ni ni-search" />
                                  </a>
                                </li> */}
                                <li className="btn-toolbar-sep" />
                                {/* <li>
                                  <div className="dropdown">
                                    <a
                                      href="#/"
                                      className="btn btn-trigger btn-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                    >
                                      <em className="icon ni ni-filter-alt" />
                                    </a>
                                    <div className="filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-end">
                                      <div className="dropdown-head">
                                        <span className="sub-title dropdown-title">
                                          Filter
                                        </span>
                                        <div className="dropdown">
                                          <a
                                            href="#/"
                                            className="link link-light"
                                          >
                                            <em className="icon ni ni-more-h" />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="dropdown-body dropdown-body-rg">
                                        <div className="row gx-6 gy-4">
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="overline-title overline-title-alt">
                                                Type
                                              </label>
                                              <select className="form-select js-select2">
                                                <option value="any">
                                                  Any Type
                                                </option>
                                                <option value="deposit">
                                                  Deposit
                                                </option>
                                                <option value="buy">
                                                  Buy Coin
                                                </option>
                                                <option value="sell">
                                                  Sell Coin
                                                </option>
                                                <option value="transfer">
                                                  Transfer
                                                </option>
                                                <option value="withdraw">
                                                  Withdraw
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="overline-title overline-title-alt">
                                                Status
                                              </label>
                                              <select className="form-select js-select2">
                                                <option value="any">
                                                  Any Status
                                                </option>
                                                <option value="pending">
                                                  Pending
                                                </option>
                                                <option value="cancel">
                                                  Cancel
                                                </option>
                                                <option value="process">
                                                  Process
                                                </option>
                                                <option value="completed">
                                                  Completed
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="overline-title overline-title-alt">
                                                Pay Currency
                                              </label>
                                              <select className="form-select js-select2">
                                                <option value="any">
                                                  Any Coin
                                                </option>
                                                <option value="bitcoin">
                                                  Bitcoin
                                                </option>
                                                <option value="ethereum">
                                                  Ethereum
                                                </option>
                                                <option value="litecoin">
                                                  Litecoin
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <label className="overline-title overline-title-alt">
                                                Method
                                              </label>
                                              <select className="form-select js-select2">
                                                <option value="any">
                                                  Any Method
                                                </option>
                                                <option value="paypal">
                                                  PayPal
                                                </option>
                                                <option value="bank">
                                                  Bank
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group">
                                              <div className="custom-control custom-control-sm custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id="includeDel"
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="includeDel"
                                                >
                                                  {" "}
                                                  Including Deleted
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-12">
                                            <div className="form-group">
                                              <button
                                                type="button"
                                                className="btn btn-secondary"
                                              >
                                                Filter
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="dropdown-foot between">
                                        <a className="clickable" href="#/">
                                          Reset Filter
                                        </a>
                                        <a
                                          href="#savedFilter"
                                          data-bs-toggle="modal"
                                        >
                                          Save Filter
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li> */}
                                <li>
                                  <div className="dropdown">
                                    <a
                                      href="#/"
                                      className="btn btn-trigger btn-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                    >
                                      <em className="icon ni ni-setting" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                                      <ul className="link-check">
                                        <li>
                                          <span>Show</span>
                                        </li>
                                        <li
                                          onClick={() =>
                                            setDisplayedTransactions(10)
                                          }
                                          className={
                                            displayedTransactions === 10
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <a href="#/">10</a>
                                        </li>
                                        <li
                                          onClick={() =>
                                            setDisplayedTransactions(20)
                                          }
                                          className={
                                            displayedTransactions === 20
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <a href="#/">20</a>
                                        </li>
                                        <li
                                          onClick={() =>
                                            setDisplayedTransactions(50)
                                          }
                                          className={
                                            displayedTransactions === 50
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <a href="#/">50</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div
                              className={"card-search search-wrap"}
                              data-search="search"
                            >
                              <div className="search-content">
                                <a
                                  href="#/"
                                  className="search-back btn btn-icon toggle-search"
                                  data-target="search"
                                >
                                  <em className="icon ni ni-arrow-left" />
                                </a>
                                <input
                                  type="text"
                                  className="form-control border-transparent form-focus-none"
                                  placeholder="Quick search by transaction"
                                />
                                <button className="search-submit btn btn-icon">
                                  <em className="icon ni ni-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner p-0">
                          <div className="nk-tb-list nk-tb-tnx">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col">
                                <span>Details</span>
                              </div>
                              <div className="nk-tb-col tb-col-xxl">
                                <span>Source</span>
                              </div>
                              <div className="nk-tb-col tb-col-lg">
                                <span>Order</span>
                              </div>
                              <div className="nk-tb-col text-end">
                                <span>Amount</span>
                              </div>
                              {/* <div className="nk-tb-col text-end tb-col-sm">
                                <span>User</span>
                              </div> */}
                              <div className="nk-tb-col nk-tb-col-status">
                                <span className="sub-text d-none d-md-block">
                                  Status
                                </span>
                              </div>
                              <div className="nk-tb-col nk-tb-col-tools" />
                            </div>
                            {transactions?.map((transaction) => (
                              <div className="nk-tb-item" key={transaction._id}>
                                <div className="nk-tb-col">
                                  <div className="nk-tnx-type">
                                    {transaction.status === "Approved" && (
                                      <div className="nk-tnx-type-icon bg-success-dim text-success">
                                        <em className="icon ni ni-arrow-up-right" />
                                      </div>
                                    )}
                                    {transaction.status === "Pending" && (
                                      <div className="nk-tnx-type-icon bg-info-dim text-info">
                                        <em className="icon ni ni-arrow-up-right" />
                                      </div>
                                    )}
                                    {transaction.status === "Rejected" && (
                                      <div className="nk-tnx-type-icon bg-danger-dim text-danger">
                                        <em className="icon ni ni-arrow-up-right" />
                                      </div>
                                    )}
                                    <div className="nk-tnx-type-text">
                                      {transaction.type === "Deposit" && (
                                        <span className="tb-lead">
                                          Deposited Funds
                                        </span>
                                      )}
                                      {transaction.type === "Withdrawal" && (
                                        <span className="tb-lead">
                                          Withdrawal Funds
                                        </span>
                                      )}
                                      {transaction.type === "Credited" && (
                                        <span className="tb-lead">
                                          Credited Profits
                                        </span>
                                      )}
                                      <span className="tb-date">
                                        {new Date(
                                          transaction.createdAt
                                        ).toLocaleString()}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="nk-tb-col tb-col-lg">
                                  <span className="tb-lead-sub">
                                    {transaction.transactionId}
                                  </span>
                                  {transaction.status === "Approved" && (
                                    <span className="badge badge-dot bg-success">
                                      {transaction?.type
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        transaction?.type?.slice(1)}
                                    </span>
                                  )}
                                  {transaction.status === "Pending" && (
                                    <span className="badge badge-dot bg-info">
                                      {transaction?.type
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        transaction?.type?.slice(1)}
                                    </span>
                                  )}
                                  {transaction.status === "Rejected" && (
                                    <span className="badge badge-dot bg-danger">
                                      {transaction?.type
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        transaction?.type?.slice(1)}
                                    </span>
                                  )}
                                </div>
                                <div className="nk-tb-col text-end">
                                  <span className="tb-amount">
                                    {transaction.usd}
                                    <span> USD</span>
                                  </span>
                                  {transaction.btc ? (
                                    <span className="tb-amount-sm">
                                      {transaction.btc} BTC
                                    </span>
                                  ) : (
                                    <span className="tb-amount-sm">
                                      {transaction.eth} ETH
                                    </span>
                                  )}
                                </div>
                                {/* <div className="nk-tb-col text-end tb-col-sm">
                                  <span className="tb-amount">
                                    {transaction.user.name}
                                  </span>
                                  <span className="tb-amount-sm">
                                    {transaction.user.email}
                                  </span>
                                </div> */}
                                {transaction.status === "Approved" && (
                                  <div className="nk-tb-col nk-tb-col-status">
                                    <div className="dot dot-success d-md-none" />
                                    <span className="badge badge-sm badge-dim bg-outline-success d-none d-md-inline-flex">
                                      Approved
                                    </span>
                                  </div>
                                )}
                                {transaction.status === "Pending" && (
                                  <div className="nk-tb-col nk-tb-col-status">
                                    <div className="dot dot-info d-md-none" />
                                    <span className="badge badge-sm badge-dim bg-outline-info d-none d-md-inline-flex">
                                      Pending
                                    </span>
                                  </div>
                                )}
                                {transaction.status === "Rejected" && (
                                  <div className="nk-tb-col nk-tb-col-status">
                                    <div className="dot dot-danger d-md-none" />
                                    <span className="badge badge-sm badge-dim bg-outline-danger d-none d-md-inline-flex">
                                      Rejected
                                    </span>
                                  </div>
                                )}
                                <div className="nk-tb-col nk-tb-col-tools">
                                  <ul className="nk-tb-actions gx-2">
                                    <li>
                                      <div className="dropdown">
                                        <a
                                          href="#/"
                                          className="dropdown-toggle bg-white btn btn-sm btn-outline-light btn-icon"
                                          data-bs-toggle="dropdown"
                                        >
                                          <em className="icon ni ni-more-h" />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          <ul className="link-list-opt">
                                            <li>
                                              <a
                                                href="#/"
                                                onClick={() => {
                                                  setIdToDelete(
                                                    transaction._id
                                                  );
                                                  setDeleteModalOpen(true);
                                                }}
                                              >
                                                <em className="icon ni ni-trash" />
                                                <span>Delete</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="#/"
                                                onClick={() => {
                                                  setTransactionToView(
                                                    transaction
                                                  );
                                                  setDetailsModalOpen(true);
                                                }}
                                              >
                                                <em className="icon ni ni-eye" />
                                                <span>View Details</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ))}
                            {transactions?.length === 0 && (
                              <p
                                style={{
                                  position: "relative",
                                  left: "50%",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                No records
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {recordsToDisplay < transactions?.length && (
                <div className="text-center pt-4">
                  <a
                    href="#/"
                    className="link link-soft"
                    onClick={loadMoreRecords}
                  >
                    <em className="icon ni ni-redo" />
                    <span>Load More</span>
                  </a>
                </div>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="#0971fe"
      />

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />

      {deleteModalOpen && (
        <>
          <div className="modal-backdrop fade show" />
          <div
            className="modal fade show"
            id="deleteEventPopup"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal py-4">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title text-[20px] font-[700]">
                      Are You Sure?
                    </h4>
                    <div className="nk-modal-text mt-n2">
                      <p className="text-soft">
                        This transaction will be deleted permanently.
                      </p>
                    </div>
                    <ul className="d-flex justify-content-center gx-4 mt-4">
                      <li>
                        {loading ? (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            disabled
                          >
                            Deleting...
                          </button>
                        ) : (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            onClick={deleteTransaction}
                          >
                            Yes, Delete
                          </button>
                        )}
                      </li>
                      <li>
                        <button
                          className="btn btn-danger btn-dim"
                          onClick={() => setDeleteModalOpen(false)}
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {detailsModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="tranxDetails"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <a
                  href="#/"
                  onClick={() => setDetailsModalOpen(false)}
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross" />
                </a>
                <div className="modal-body modal-body-md">
                  <div className="nk-modal-head mb-3 mb-sm-5">
                    <h4 className="nk-modal-title title text-[20px] font-[700]">
                      Transaction{" "}
                      <small className="text-primary">
                        #{transactionToView?.transactionId}
                      </small>
                    </h4>
                  </div>
                  <div className="nk-tnx-details">
                    <div className="nk-block-between flex-wrap g-3">
                      <div className="nk-tnx-type">
                        {transactionToView?.status === "Approved" && (
                          <div className="nk-tnx-type-icon bg-success text-white">
                            <em className="icon ni ni-arrow-up-right" />
                          </div>
                        )}
                        {transactionToView?.status === "Pending" && (
                          <div className="nk-tnx-type-icon bg-info text-white">
                            <em className="icon ni ni-arrow-up-right" />
                          </div>
                        )}
                        {transactionToView?.status === "Rejected" && (
                          <div className="nk-tnx-type-icon bg-danger text-white">
                            <em className="icon ni ni-arrow-up-right" />
                          </div>
                        )}
                        <div className="nk-tnx-type-text">
                          <h5 className="title text-[18px] font-[700] mb-1">
                            {transactionToView?.usd} USD
                          </h5>
                          <span className="sub-text mt-n1">
                            {new Date(
                              transactionToView?.createdAt
                            ).toLocaleString()}
                          </span>
                        </div>
                      </div>
                      <ul className="align-center flex-wrap gx-3">
                        <li>
                          {transactionToView?.status === "Approved" && (
                            <span className="badge badge-sm bg-success">
                              Approved
                            </span>
                          )}
                          {transactionToView?.status === "Pending" && (
                            <span className="badge badge-sm bg-info">
                              Pending
                            </span>
                          )}
                          {transactionToView?.status === "Rejected" && (
                            <span className="badge badge-sm bg-danger">
                              Rejected
                            </span>
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className="nk-modal-head mt-sm-5 mt-4 mb-4">
                      <h5 className="title text-[18px] font-[700]">
                        Transaction Info
                      </h5>
                    </div>
                    <div className="row gy-3">
                      <div className="col-lg-6">
                        <span className="sub-text">Transaction ID</span>
                        <span className="caption-text">
                          {transactionToView?.transactionId}
                        </span>
                      </div>

                      <div className="col-lg-6">
                        <span className="sub-text">Transaction Amount</span>
                        <span className="caption-text">
                          {transactionToView?.usd} USD
                        </span>
                      </div>
                      {transactionToView?.btc ? (
                        <div className="col-lg-6">
                          <span className="sub-text">BTC</span>
                          <span className="caption-text">
                            {transactionToView?.btc} BTC
                          </span>
                        </div>
                      ) : (
                        <div className="col-lg-6">
                          <span className="sub-text">ETH</span>
                          <span className="caption-text">
                            {transactionToView?.eth} ETH
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="nk-modal-head mt-sm-5 mt-4 mb-4">
                      <h5 className="title text-[18px] font-[700]">
                        Transaction Details
                      </h5>
                    </div>
                    <div className="row gy-3">
                      <div className="col-lg-6">
                        <span className="sub-text">Transaction Type</span>
                        <span className="caption-text">
                          {transactionToView?.type}
                        </span>
                      </div>
                      {transactionToView?.proof && (
                        <div className="col-lg-6">
                          <span className="sub-text">Payment Proof</span>
                          <a
                            href={transactionToView?.proof}
                            target="_blank"
                            rel="noreferrer"
                            className="caption-text align-center"
                          >
                            View
                          </a>
                        </div>
                      )}

                      {transactionToView?.btcAddress && (
                        <div className="col-lg-12">
                          <span className="sub-text">BTC Wallet</span>
                          <span className="caption-text text-break">
                            {transactionToView?.btcAddress}
                          </span>
                        </div>
                      )}
                      {transactionToView?.ethAddress && (
                        <div className="col-lg-12">
                          <span className="sub-text">ETH Wallet</span>
                          <span className="caption-text text-break">
                            {transactionToView?.ethAddress}
                          </span>
                        </div>
                      )}
                      {transactionToView?.remarks && (
                        <div className="col-lg-12">
                          <span className="sub-text">Remarks</span>
                          <span className="caption-text">
                            {transactionToView?.remarks}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </>
      )}
    </div>
  );
};

export default UserTransactions;
