import React from "react";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import logo from "../../assets/whinstein_3.png";

const KycSubmitted = () => {
  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main">
          <div className="nk-wrap nk-wrap-nosidebar">
            <div className="nk-content">
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  <Link to="/" className="logo-link">
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={logo}
                      alt="logo-dark"
                    />
                  </Link>
                </div>
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title text-[20px] lg:text-[24px] font-[700]">
                      Thank you for submitting form
                    </h4>
                    <div className="nk-block-des text-success">
                      <p>
                        We will review your application and respond via email.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KycSubmitted;
