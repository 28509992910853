import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Withdraw = () => {
  const [coinSelected, setCoinSelected] = useState("btc");
  const [usd, setUsd] = useState("");
  const [allowWithdrawal, setAllowWithdrawal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btcAddress, setBtcAddress] = useState("");
  const [ethAddress, setEthAddress] = useState("");
  const [btcRate, setBtcRate] = useState("");
  const [ethRate, setEthRate] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    const getPrice = async () => {
      const { data } = await axios.get(
        "https://rest.coinapi.io/v1/exchangerate/BTC/USD",
        {
          headers: {
            "X-CoinAPI-Key": "EB9C18A1-0064-450D-BEA1-071706C3AEA5",
          },
        }
      );

      setBtcRate(Math.round(data.rate));
    };

    getPrice();
  }, []);

  useEffect(() => {
    const getPrice = async () => {
      const { data } = await axios.get(
        "https://rest.coinapi.io/v1/exchangerate/ETH/USD",
        {
          headers: {
            "X-CoinAPI-Key": "EB9C18A1-0064-450D-BEA1-071706C3AEA5",
          },
        }
      );

      setEthRate(Math.round(data.rate));
    };

    getPrice();
  }, []);

  // useEffect(() => {
  //   setBtcRate(21090);
  //   setEthRate(1552);
  // }, []);

  useEffect(() => {
    if (
      usd &&
      +usd >= 500 &&
      +usd <= user?.balance &&
      ((coinSelected === "btc" && +user?.btc > 0) ||
        (coinSelected === "eth" && +user?.eth > 0)) &&
      (btcAddress || ethAddress)
    ) {
      setAllowWithdrawal(true);
    } else {
      setAllowWithdrawal(false);
    }
  }, [
    usd,
    user?.balance,
    coinSelected,
    user?.btc,
    user?.eth,
    btcAddress,
    ethAddress,
  ]);

  const withdrawalHandler = async () => {
    const token = localStorage.getItem("whinsteintkn");
    setLoading(true);

    try {
      await axios.post(
        `https://api.whinstein.com/api/transaction/${user?._id}/withdraw`,
        {
          usd: +usd,
          coinSelected,
          btc: +(usd / btcRate)?.toFixed(6),
          eth: +(usd / ethRate)?.toFixed(6),
          user,
          transactionId: new Date().getTime().toString(),
          btcAddress,
          ethAddress,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setConfirmModalOpen(true);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="buysell wide-xs m-auto">
                    <div className="buysell-nav text-center">
                      <ul className="nk-nav nav nav-tabs nav-tabs-s2">
                        <li className="nav-item">
                          <a className="nav-link text-lg" href="#/">
                            Withdraw Funds
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="buysell-block">
                      <form
                        onSubmit={(e) => e.preventDefault()}
                        className="buysell-form"
                      >
                        <div className="buysell-field form-group">
                          <div className="form-label-group">
                            <label className="form-label">
                              Choose what you want to withdraw
                            </label>
                          </div>
                          <input
                            type="hidden"
                            defaultValue="btc"
                            name="bs-currency"
                            id="buysell-choose-currency"
                          />
                          <div className="dropdown buysell-cc-dropdown">
                            <a
                              href="#/"
                              className="buysell-cc-choosen dropdown-indicator"
                              data-bs-toggle="dropdown"
                            >
                              {coinSelected === "btc" ? (
                                <div className="coin-item coin-btc">
                                  <div className="coin-icon">
                                    <em className="icon ni ni-sign-btc-alt" />
                                  </div>
                                  <div className="coin-info">
                                    <span className="coin-name">
                                      Bitcoin (BTC)
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="coin-item coin-eth">
                                  <div className="coin-icon">
                                    <em className="icon ni ni-sign-eth-alt" />
                                  </div>
                                  <div className="coin-info">
                                    <span className="coin-name">
                                      Ethereum (ETH)
                                    </span>
                                  </div>
                                </div>
                              )}
                            </a>
                            <div className="dropdown-menu dropdown-menu-auto dropdown-menu-mxh">
                              <ul className="buysell-cc-list">
                                <li
                                  className="buysell-cc-item"
                                  onClick={() => {
                                    setCoinSelected("btc");
                                    setBtcAddress("");
                                    setEthAddress("");
                                  }}
                                >
                                  <a
                                    href="#/"
                                    className="buysell-cc-opt"
                                    data-currency="btc"
                                  >
                                    <div className="coin-item coin-btc">
                                      <div className="coin-icon">
                                        <em className="icon ni ni-sign-btc-alt" />
                                      </div>
                                      <div className="coin-info">
                                        <span className="coin-name">
                                          Bitcoin (BTC)
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li
                                  className="buysell-cc-item"
                                  onClick={() => {
                                    setCoinSelected("eth");
                                    setBtcAddress("");
                                    setEthAddress("");
                                  }}
                                >
                                  <a
                                    href="#/"
                                    className="buysell-cc-opt"
                                    data-currency="eth"
                                  >
                                    <div className="coin-item coin-eth">
                                      <div className="coin-icon">
                                        <em className="icon ni ni-sign-eth-alt" />
                                      </div>
                                      <div className="coin-info">
                                        <span className="coin-name">
                                          Ethereum (ETH)
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="buysell-field form-group">
                          <div className="form-label-group">
                            <label
                              className="form-label"
                              htmlFor="buysell-amount"
                            >
                              Amount to Withdraw (USD)
                            </label>
                          </div>
                          <div className="form-control-group">
                            <input
                              type="text"
                              className="form-control form-control-lg form-control-number"
                              id="buysell-amount"
                              name="bs-amount"
                              placeholder={1000}
                              value={usd}
                              onChange={(e) => {
                                setUsd(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-note-group">
                            <span className="buysell-min form-note-alt">
                              Minimum: 500 USD
                            </span>
                          </div>
                        </div>
                        <div className="buysell-field form-group">
                          <div className="form-label-group">
                            <label
                              className="form-label"
                              htmlFor="buysell-amount"
                            >
                              Your {coinSelected?.toUpperCase()} wallet address
                            </label>
                          </div>
                          {coinSelected === "btc" ? (
                            <div className="form-control-group">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-number"
                                id="buysell-amount"
                                name="bs-amount"
                                placeholder="Your bitcoin wallet address"
                                value={btcAddress}
                                onChange={(e) => {
                                  setBtcAddress(e.target.value);
                                }}
                              />
                            </div>
                          ) : (
                            <div className="form-control-group">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-number"
                                id="buysell-amount"
                                name="bs-amount"
                                placeholder="Your ethereum wallet address"
                                value={ethAddress}
                                onChange={(e) => {
                                  setEthAddress(e.target.value);
                                }}
                              />
                            </div>
                          )}
                        </div>
                        {allowWithdrawal ? (
                          <div className="buysell-field form-action">
                            {loading ? (
                              <button
                                className="btn btn-lg btn-block btn-primary"
                                disabled
                              >
                                Confirming...
                              </button>
                            ) : (
                              <button
                                className="btn btn-lg btn-block btn-primary"
                                onClick={withdrawalHandler}
                              >
                                Confirm Withdrawal
                              </button>
                            )}
                          </div>
                        ) : (
                          <div className="buysell-field form-action">
                            <button
                              className="btn btn-lg btn-block btn-primary"
                              disabled
                            >
                              Confirm Withdrawal
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>

      {confirmModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            role="dialog"
            id="confirm-coin"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => navigate(0)}
                >
                  <em className="icon ni ni-cross-sm" />
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success" />
                    <h4 className="nk-modal-title text-[20px] font-[700]">
                      Withdrawal request received!
                    </h4>
                    <div className="nk-modal-text">
                      {coinSelected === "btc" ? (
                        <p className="caption-text">
                          Your request to withdraw{" "}
                          <strong>{(usd / btcRate)?.toFixed(6)}</strong> BTC for
                          <strong> {usd}</strong> USD has been received and is
                          being reviewed.
                        </p>
                      ) : (
                        <p className="caption-text">
                          Your request to withdraw{" "}
                          <strong>{(usd / ethRate)?.toFixed(6)}</strong> ETH for
                          <strong> {usd}</strong> USD has been received and is
                          being reviewed.
                        </p>
                      )}
                    </div>
                    <div className="nk-modal-action-lg">
                      <ul className="btn-group gx-4">
                        <li>
                          <a
                            href="#/"
                            onClick={() => navigate(0)}
                            className="btn btn-lg btn-mw btn-primary"
                          >
                            Return
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </>
      )}

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />
    </div>
  );
};

export default Withdraw;
