import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/userRedux";
import { browserName, osName } from "react-device-detect";
import logo from "../../assets/whinstein_3.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailFieldEmpty, setEmailFieldEmpty] = useState(false);
  const [passwordFieldEmpty, setPasswordFieldEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [ip, setIp] = useState();
  const [browser, setBrowser] = useState();
  const [os, setOs] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setBrowser(browserName);
    setOs(osName);

    const getData = async () => {
      const { data } = await axios.get("https://geolocation-db.com/json/");
      setIp(data.IPv4);
    };

    getData();
  }, []);

  const login = async (e) => {
    e.preventDefault();

    const emailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email || !email.match(emailFormat)) {
      setEmailFieldEmpty(true);
      return;
    } else {
      setEmailFieldEmpty(false);
    }

    if (!password) {
      setPasswordFieldEmpty(true);
      return;
    } else {
      setPasswordFieldEmpty(false);
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        "https://api.whinstein.com/api/auth/login",
        {
          email,
          password,
        }
      );

      await axios.post("https://api.whinstein.com/api/activity", {
        ip,
        browser,
        os,
        userId: data.others._id,
      });

      setLoading(false);
      dispatch(loginUser(data));
      localStorage.setItem("whinsteintkn", data.token);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.error === "Account restricted") {
          toast.error("Account restricted. Please contact support");
        } else {
          toast.error(error.response.data.error);
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="nk-body bg-white npc-general pg-auth">
      <div className="nk-app-root">
        <div className="nk-main ">
          <div className="nk-wrap nk-wrap-nosidebar">
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <Link to="/" className="logo-link">
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={logo}
                      alt="logo-dark"
                    />
                  </Link>
                </div>
                <div className="card card-bordered">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title text-[20px] lg:text-[24px] font-[700] mb-2">
                          Login
                        </h4>
                      </div>
                    </div>
                    <form onSubmit={login}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Email address
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="default-01"
                            placeholder="Enter your email address"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setEmailFieldEmpty(false);
                            }}
                          />
                          {emailFieldEmpty && (
                            <p
                              className="mt-3 text-center"
                              style={{ color: "red" }}
                            >
                              Please enter a valid email address
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                          <Link
                            className="link link-primary link-sm"
                            to="/forgot-password"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        <div className="form-control-wrap">
                          <a
                            href="#/"
                            onClick={() => setPasswordShown(!passwordShown)}
                            className="form-icon form-icon-right passcode-switch lg"
                            data-target="password"
                          >
                            {!passwordShown ? (
                              <em className="passcode-icon icon-show icon ni ni-eye" />
                            ) : (
                              <em className="passcode-icon icon-show icon ni ni-eye-off" />
                            )}
                          </a>
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="form-control form-control-lg"
                            id="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setPasswordFieldEmpty(false);
                            }}
                          />
                          {passwordFieldEmpty && (
                            <p
                              className="mt-3 text-center"
                              style={{ color: "red" }}
                            >
                              Please enter your password
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        {loading ? (
                          <button
                            className="btn btn-lg btn-primary btn-block"
                            disabled
                          >
                            Signing in...
                          </button>
                        ) : (
                          <button className="btn btn-lg btn-primary btn-block">
                            Sign in
                          </button>
                        )}
                      </div>
                    </form>
                    <div className="form-note-s2 text-center pt-4">
                      New on our platform?{" "}
                      <Link className="text-[#3a8dfe]" to="/register">
                        Create an account
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-footer nk-auth-footer-full">
                <div className="container wide-lg">
                  <div className="row g-3">
                    <div className="col-lg-6">
                      <div className="nk-block-content text-center text-lg-left">
                        <p className="text-soft">
                          © {new Date().getFullYear()} Whinstein. All Rights
                          Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />
    </div>
  );
};

export default Login;
