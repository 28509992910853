import React, { useState, useRef, useEffect } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "react-fullscreen-loading";

const Deposit = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [coinSelected, setCoinSelected] = useState("btc");
  const [usd, setUsd] = useState("");
  const [usdEmpty, setUsdEmpty] = useState(false);
  const [btcAddress, setBtcAddress] = useState("");
  const [ethAddress, setEthAddress] = useState("");
  const [btcAddressCopied, setBtcAddressCopied] = useState(false);
  const [ethAddressCopied, setEthAddressCopied] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [noSelectedFile, setNoSelectedFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [lowAmount, setLowAmount] = useState(false);
  const [btcRate, setBtcRate] = useState("");
  const [ethRate, setEthRate] = useState("");

  const inputRef = useRef(null);
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    const getDetails = async () => {
      const { data } = await axios.get("https://api.whinstein.com/api/wallet");

      setBtcAddress(data.btcAddress);
      setEthAddress(data.ethAddress);
      setPageLoading(false);
    };

    getDetails();
  }, []);

  useEffect(() => {
    const getPrice = async () => {
      const { data } = await axios.get(
        "https://rest.coinapi.io/v1/exchangerate/BTC/USD",
        {
          headers: {
            "X-CoinAPI-Key": "EB9C18A1-0064-450D-BEA1-071706C3AEA5",
          },
        }
      );

      setBtcRate(Math.round(data.rate));
    };

    getPrice();
  }, []);

  useEffect(() => {
    const getPrice = async () => {
      const { data } = await axios.get(
        "https://rest.coinapi.io/v1/exchangerate/ETH/USD",
        {
          headers: {
            "X-CoinAPI-Key": "EB9C18A1-0064-450D-BEA1-071706C3AEA5",
          },
        }
      );

      setEthRate(Math.round(data.rate));
    };

    getPrice();
  }, []);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSelectedFile(e.dataTransfer.files[0]);
      setFileUploaded(true);
      setNoSelectedFile(false);
    } else {
      setSelectedFile(null);
      setNoSelectedFile(true);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setFileUploaded(true);
      setNoSelectedFile(false);
    } else {
      setSelectedFile(null);
      setNoSelectedFile(true);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const cancelUpload = () => {
    setFileUploaded(false);
    setSelectedFile(null);
  };

  const depositHandler = () => {
    if (!usd) {
      setUsdEmpty(true);
      return;
    } else {
      setUsdEmpty(false);
    }

    if (usd < 500) {
      setLowAmount(true);
      return;
    } else {
      setLowAmount(false);
    }

    if (!selectedFile) {
      setNoSelectedFile(true);
      return;
    } else {
      setNoSelectedFile(false);
    }

    const fileName = new Date().getTime() + selectedFile.name;
    const storage = getStorage(app);
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    setLoading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        setLoading(false);
        toast.error("Something went wrong");
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const deposit = async () => {
            try {
              await axios.post(
                "https://api.whinstein.com/api/transaction/deposit",
                {
                  usd: +usd,
                  coinSelected,
                  btc: +(usd / btcRate)?.toFixed(6),
                  eth: +(usd / ethRate)?.toFixed(6),
                  proof: downloadURL,
                  remarks,
                  user,
                  transactionId: new Date().getTime().toString(),
                }
              );

              setLoading(false);
              setConfirmModalOpen(true);
            } catch (error) {
              setLoading(false);
              toast.error("Something went wrong");
            }
          };

          deposit();
        });
      }
    );
  };

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="buysell wide-xs m-auto">
                    <div className="buysell-nav text-center">
                      <ul className="nk-nav nav nav-tabs nav-tabs-s2">
                        <li className="nav-item">
                          <a className="nav-link text-lg" href="#/">
                            Deposit Funds
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="buysell-block">
                      <form
                        onSubmit={(e) => e.preventDefault()}
                        className="buysell-form"
                      >
                        <div className="buysell-field form-group">
                          <div className="form-label-group">
                            <label className="form-label">
                              Choose what you want to deposit
                            </label>
                          </div>
                          <input
                            type="hidden"
                            defaultValue="btc"
                            name="bs-currency"
                            id="buysell-choose-currency"
                          />
                          <div className="dropdown buysell-cc-dropdown">
                            <a
                              href="#/"
                              className="buysell-cc-choosen dropdown-indicator"
                              data-bs-toggle="dropdown"
                            >
                              {coinSelected === "btc" ? (
                                <div className="coin-item coin-btc">
                                  <div className="coin-icon">
                                    <em className="icon ni ni-sign-btc-alt" />
                                  </div>
                                  <div className="coin-info">
                                    <span className="coin-name">
                                      Bitcoin (BTC)
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="coin-item coin-eth">
                                  <div className="coin-icon">
                                    <em className="icon ni ni-sign-eth-alt" />
                                  </div>
                                  <div className="coin-info">
                                    <span className="coin-name">
                                      Ethereum (ETH)
                                    </span>
                                  </div>
                                </div>
                              )}
                            </a>
                            <div className="dropdown-menu dropdown-menu-auto dropdown-menu-mxh">
                              <ul className="buysell-cc-list">
                                <li
                                  className="buysell-cc-item"
                                  onClick={() => setCoinSelected("btc")}
                                >
                                  <a
                                    href="#/"
                                    className="buysell-cc-opt"
                                    data-currency="btc"
                                  >
                                    <div className="coin-item coin-btc">
                                      <div className="coin-icon">
                                        <em className="icon ni ni-sign-btc-alt" />
                                      </div>
                                      <div className="coin-info">
                                        <span className="coin-name">
                                          Bitcoin (BTC)
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li
                                  className="buysell-cc-item"
                                  onClick={() => setCoinSelected("eth")}
                                >
                                  <a
                                    href="#/"
                                    className="buysell-cc-opt"
                                    data-currency="eth"
                                  >
                                    <div className="coin-item coin-eth">
                                      <div className="coin-icon">
                                        <em className="icon ni ni-sign-eth-alt" />
                                      </div>
                                      <div className="coin-info">
                                        <span className="coin-name">
                                          Ethereum (ETH)
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="buysell-field form-group">
                          <div className="form-label-group">
                            <label
                              className="form-label"
                              htmlFor="buysell-amount"
                            >
                              Amount to Buy (USD)
                            </label>
                          </div>
                          <div className="form-control-group">
                            <input
                              type="number"
                              className="form-control form-control-lg form-control-number"
                              id="buysell-amount"
                              name="bs-amount"
                              placeholder="100,000"
                              value={usd}
                              onChange={(e) => {
                                setUsd(e.target.value);
                                setUsdEmpty(false);
                                setLowAmount(false);
                              }}
                            />
                            <div className="form-dropdown">
                              <div className="text">
                                {coinSelected?.toUpperCase()}
                                <span>/</span>
                              </div>
                              <div className="dropdown">
                                <a href="#/">USD</a>
                              </div>
                            </div>
                          </div>
                          <div className="form-note-group">
                            <span className="buysell-min form-note-alt">
                              Minimum: 500 USD
                            </span>
                            {coinSelected === "btc" ? (
                              <span className="buysell-rate form-note-alt">
                                1 USD = {(1 / btcRate)?.toFixed(6)} BTC
                              </span>
                            ) : (
                              <span className="buysell-rate form-note-alt">
                                1 USD = {(1 / ethRate)?.toFixed(6)} ETH
                              </span>
                            )}
                          </div>
                          {usdEmpty && (
                            <p className="text-center text-[red] mt-2">
                              Please enter amount
                            </p>
                          )}
                          {lowAmount && (
                            <p className="text-center text-[red] mt-2">
                              Please enter an amount greater than or equivalent
                              to 500.
                            </p>
                          )}
                        </div>
                        <div className="buysell-field form-group">
                          <div className="form-label-group">
                            <label className="form-label">
                              Transfer {usd} USD to this{" "}
                              {coinSelected?.toUpperCase()} wallet address
                            </label>
                          </div>
                          {coinSelected === "btc" ? (
                            <div className="form-control-group">
                              <input
                                type="text"
                                className="form-control"
                                name="bs-amount"
                                value={btcAddress}
                                disabled
                              />
                              {btcAddressCopied ? (
                                <div className="form-clip clipboard-init bg-white z-50">
                                  <span className="clipboard-text text-success">
                                    Copied!
                                  </span>
                                </div>
                              ) : (
                                <div
                                  className="form-clip clipboard-init bg-white z-50"
                                  onClick={() => {
                                    navigator.clipboard.writeText(btcAddress);
                                    setBtcAddressCopied(true);
                                    setTimeout(() => {
                                      setBtcAddressCopied(false);
                                    }, 2000);
                                  }}
                                >
                                  <em className="clipboard-icon icon ni ni-copy" />
                                  <span className="clipboard-text">Copy</span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="form-control-group">
                              <input
                                type="text"
                                className="form-control"
                                name="bs-amount"
                                value={ethAddress}
                                disabled
                              />
                              {ethAddressCopied ? (
                                <div className="form-clip clipboard-init bg-white z-50">
                                  <span className="clipboard-text text-success">
                                    Copied!
                                  </span>
                                </div>
                              ) : (
                                <div
                                  className="form-clip clipboard-init bg-white z-50"
                                  onClick={() => {
                                    navigator.clipboard.writeText(ethAddress);
                                    setEthAddressCopied(true);
                                    setTimeout(() => {
                                      setEthAddressCopied(false);
                                    }, 2000);
                                  }}
                                >
                                  <em className="clipboard-icon icon ni ni-copy" />
                                  <span className="clipboard-text">Copy</span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        <div className="form-label-group">
                          <label className="form-label">
                            Show proof of payment
                          </label>
                        </div>

                        <div className="nk-kycfm-upload">
                          <h6 className="title nk-kycfm-upload-title mb-2">
                            Upload here
                          </h6>
                          <div className="row align-items-center">
                            <div className="col-sm-12">
                              <div
                                className="nk-kycfm-upload-box"
                                style={{ position: "relative" }}
                                onDragEnter={handleDrag}
                              >
                                <div className="upload-zone dropzone dz-clickable">
                                  <input
                                    ref={inputRef}
                                    type="file"
                                    id="input-file-upload"
                                    style={{ display: "none" }}
                                    onChange={handleChange}
                                    accept="image/*"
                                  />
                                  <div className="dz-message" data-dz-message>
                                    {fileUploaded && (
                                      <img
                                        style={{
                                          height: "90%",
                                          marginBottom: "10px",
                                        }}
                                        src={URL.createObjectURL(selectedFile)}
                                        alt="id"
                                      />
                                    )}
                                    {!fileUploaded && (
                                      <>
                                        <span className="dz-message-text">
                                          Drag and drop file
                                        </span>
                                        <span className="dz-message-or">
                                          or
                                        </span>
                                      </>
                                    )}
                                    {fileUploaded ? (
                                      <button
                                        className="btn btn-primary"
                                        onClick={cancelUpload}
                                      >
                                        CANCEL
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-primary"
                                        onClick={onButtonClick}
                                      >
                                        SELECT
                                      </button>
                                    )}
                                  </div>
                                </div>
                                {dragActive && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "100%",
                                      top: "0",
                                      bottom: "0",
                                      right: "0",
                                      left: "0",
                                      borderWidth: "2px",
                                      borderColor: "#526484",
                                      borderStyle: "dotted",
                                      borderRadius: "4px",
                                    }}
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <p style={{ marginTop: "4px" }}>
                          Uploaded file should be less than 4MB
                        </p>
                        {noSelectedFile && (
                          <p className="mt-1 text-danger">
                            Please upload proof
                          </p>
                        )}

                        <div className="form-label-group mt-4">
                          <label className="form-label" htmlFor="remarks">
                            Remarks (optional)
                          </label>
                        </div>
                        <textarea
                          id="remarks"
                          className="form-control form-control-lg form-control-number text-sm"
                          value={remarks}
                          onChange={(e) => setRemarks(e.target.value)}
                        />

                        {loading ? (
                          <div className="buysell-field form-action mt-4">
                            <button
                              className="btn btn-lg btn-block btn-primary"
                              data-bs-toggle="modal"
                              disabled
                            >
                              Confirming...
                            </button>
                          </div>
                        ) : (
                          <div className="buysell-field form-action mt-4">
                            <button
                              onClick={depositHandler}
                              className="btn btn-lg btn-block btn-primary"
                              data-bs-toggle="modal"
                            >
                              Confirm Deposit
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />

      {confirmModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            role="dialog"
            id="confirm-coin"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <a
                  href="#/"
                  className="close"
                  data-bs-dismiss="modal"
                  onClick={() => navigate(0)}
                >
                  <em className="icon ni ni-cross-sm" />
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success" />
                    <h4 className="nk-modal-title text-[20px] font-[700]">
                      Deposit request received!
                    </h4>
                    <div className="nk-modal-text">
                      {coinSelected === "btc" ? (
                        <p className="caption-text">
                          Your request to deposit{" "}
                          <strong>{(usd / btcRate)?.toFixed(6)}</strong> BTC for
                          <strong> {usd}</strong> USD has been received and is
                          being reviewed.
                        </p>
                      ) : (
                        <p className="caption-text">
                          Your request to deposit{" "}
                          <strong>{(usd / ethRate)?.toFixed(6)}</strong> ETH for
                          <strong> {usd}</strong> USD has been received and is
                          being reviewed.
                        </p>
                      )}
                    </div>
                    <div className="nk-modal-action-lg">
                      <ul className="btn-group gx-4">
                        <li>
                          <a
                            href="#/"
                            onClick={() => navigate(0)}
                            className="btn btn-lg btn-mw btn-primary"
                          >
                            Return
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </>
      )}

      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="#0971fe"
      />
    </div>
  );
};

export default Deposit;
