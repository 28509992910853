import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import logo from "../../assets/whinstein_3.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordFieldEmpty, setPasswordFieldEmpty] = useState(false);
  const [confirmPasswordFieldEmpty, setConfirmPasswordFieldEmpty] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);

  const id = useParams();
  const navigate = useNavigate();

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    if (!password) {
      setPasswordFieldEmpty(true);
      return;
    } else {
      setPasswordFieldEmpty(false);
    }

    if (password.length < 6) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (!confirmPassword) {
      setConfirmPasswordFieldEmpty(true);
      return;
    } else {
      setConfirmPasswordFieldEmpty(false);
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    setLoading(true);

    try {
      await axios.put(
        `https://api.whinstein.com/api/auth/passwordreset/${id.resetToken}`,
        {
          password,
        }
      );

      setLoading(false);
      toast.success("Password reset successfully");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main">
          <div className="nk-wrap nk-wrap-nosidebar">
            <div className="nk-content ">
              <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <Link to="/" className="logo-link">
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={logo}
                      alt="logo-dark"
                    />
                  </Link>
                </div>
                <div className="card card-bordered">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h5 className="nk-block-title text-[20px] lg:text-[24px] font-[700]">
                          Reset Password
                        </h5>
                      </div>
                    </div>
                    <form onSubmit={resetPasswordHandler}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            New Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            id="default-01"
                            placeholder="Enter a new password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setPasswordFieldEmpty(false);
                              setPasswordWeak(false);
                              setPasswordError(false);
                            }}
                          />
                          {passwordFieldEmpty && (
                            <p className="mt-3 text-[red] text-center">
                              This field is required
                            </p>
                          )}
                          {passwordWeak && (
                            <p className="mt-3 text-[red] text-center">
                              Password should be at least 6 characters
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Confirm Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            id="default-01"
                            placeholder="Confirm password"
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              setConfirmPasswordFieldEmpty(false);
                              setPasswordError(false);
                            }}
                          />
                          {confirmPasswordFieldEmpty && (
                            <p className="mt-3 text-[red] text-center">
                              This field is required
                            </p>
                          )}
                          {passwordError && (
                            <p className="mt-3 text-[red] text-center">
                              Passwords do not match
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        {loading ? (
                          <button
                            className="btn btn-lg btn-primary btn-block"
                            type="button"
                            disabled
                          >
                            Resetting...
                          </button>
                        ) : (
                          <button className="btn btn-lg btn-primary btn-block">
                            Reset Password
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />
    </div>
  );
};

export default PasswordReset;
