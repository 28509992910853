import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideBarContext from "../store/sidebar-context";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/userRedux";
import logo from "../assets/whinstein_3.png";

const Sidebar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);

  const user = useSelector((state) => state.user.currentUser);
  const { sideBarOpen, toggleSidebar } = useContext(SideBarContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initials = user?.name
    .match(/(^\S\S?|\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleAdminDropdown = () => {
    setAdminDropdownOpen(!adminDropdownOpen);
  };

  const logout = () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate("/login");
  };

  return (
    <div
      className={`nk-sidebar nk-sidebar-fixed ${
        sideBarOpen ? "nk-sidebar-mobile nk-sidebar-active" : ""
      }`}
      // data-content="sidebarMenu"
    >
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-sidebar-brand">
          <Link to="/" className="logo-link nk-sidebar-logo">
            <img className="logo-dark logo-img" src={logo} alt="logo-dark" />
          </Link>
        </div>
        <div className="nk-menu-trigger me-n2">
          <a
            href="#/"
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left" />
          </a>
        </div>
      </div>
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-body" data-simplebar>
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-widget d-none d-xl-block">
              <div className="user-account-info between-center">
                <div className="user-account-main">
                  <h6 className="overline-title-alt">Available Balance</h6>
                  <div className="user-balance">
                    {user?.balance}{" "}
                    <small className="currency currency-btc">USD</small>
                  </div>
                  <div className="user-balance-alt">
                    {user?.btc}{" "}
                    <span className="currency currency-btc">BTC</span>
                  </div>
                  <div className="user-balance-alt">
                    {user?.eth}{" "}
                    <span className="currency currency-btc">ETH</span>
                  </div>
                </div>
                {/* <a href="#/" className="btn btn-white btn-icon btn-light">
                  <em className="icon ni ni-line-chart" />
                </a> */}
              </div>

              <div className="user-account-actions">
                <ul className="g-3">
                  <li>
                    <Link to="/deposit" className="btn btn-lg btn-primary">
                      <span>Deposit</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/withdraw" className="btn btn-lg btn-primary">
                      <span>Withdraw</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="nk-sidebar-widget nk-sidebar-widget-full d-xl-none pt-0">
              <a
                className="nk-profile-toggle toggle-expand"
                data-target="sidebarProfile"
                href="#/"
              >
                <div className="user-card-wrap">
                  <div className="user-card">
                    <div className="user-avatar">
                      <span>{initials}</span>
                    </div>
                    <div className="user-info">
                      <span className="lead-text">{user.name}</span>
                      <span className="sub-text">{user.email}</span>
                    </div>
                    <div className="user-action" onClick={toggleDropdown}>
                      <em className="icon ni ni-chevron-down" />
                    </div>
                  </div>
                </div>
              </a>
              <div
                className="nk-profile-content toggle-expand-content"
                data-content="sidebarProfile"
                style={{ display: dropdownOpen ? "block" : "none" }}
              >
                <div className="user-account-info between-center">
                  <div className="user-account-main">
                    <h6 className="overline-title-alt">Available Balance</h6>
                    <div className="user-balance">
                      {user?.balance}{" "}
                      <small className="currency currency-btc">USD</small>
                    </div>
                    <div className="user-balance-alt">
                      {user?.btc}{" "}
                      <span className="currency currency-btc">BTC</span>
                    </div>
                    <div className="user-balance-alt">
                      {user?.eth}{" "}
                      <span className="currency currency-btc">ETH</span>
                    </div>
                  </div>
                  {/* <a href="#/" className="btn btn-icon btn-light">
                    <em className="icon ni ni-line-chart" />
                  </a> */}
                </div>

                <ul className="user-account-links">
                  <li>
                    <Link
                      to="/withdraw"
                      className="link"
                      onClick={toggleSidebar}
                    >
                      <span>Withdraw Funds</span>{" "}
                      <em className="icon ni ni-wallet-out" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/deposit"
                      className="link"
                      onClick={toggleSidebar}
                    >
                      <span>Deposit Funds</span>{" "}
                      <em className="icon ni ni-wallet-in" />
                    </Link>
                  </li>
                </ul>
                <ul className="link-list">
                  <li>
                    <Link to="/profile" onClick={toggleSidebar}>
                      <em className="icon ni ni-user-alt" />
                      <span>View Profile</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/settings" onClick={toggleSidebar}>
                      <em className="icon ni ni-setting-alt" />
                      <span>Account Settings</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/activity" onClick={toggleSidebar}>
                      <em className="icon ni ni-activity-alt" />
                      <span>Login Activity</span>
                    </Link>
                  </li>
                </ul>
                <ul className="link-list">
                  <li>
                    <a href="#/" onClick={logout}>
                      <em className="icon ni ni-signout" />
                      <span>Sign out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="nk-sidebar-menu">
              <ul className="nk-menu">
                <li className="nk-menu-heading">
                  <h6 className="overline-title">Menu</h6>
                </li>
                <li className="nk-menu-item">
                  <Link
                    to="/dashboard"
                    className="nk-menu-link"
                    onClick={toggleSidebar}
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-dashboard" />
                    </span>
                    <span className="nk-menu-text">Dashboard</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link
                    to="/deposit"
                    className="nk-menu-link"
                    onClick={toggleSidebar}
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-coins" />
                    </span>
                    <span className="nk-menu-text">Deposit</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link
                    to="/withdraw"
                    className="nk-menu-link"
                    onClick={toggleSidebar}
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-wallet-alt" />
                    </span>
                    <span className="nk-menu-text">Withdraw</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link
                    to="/transactions"
                    className="nk-menu-link"
                    onClick={toggleSidebar}
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-repeat" />
                    </span>
                    <span className="nk-menu-text">Transactions</span>
                  </Link>
                </li>
                <li className="nk-menu-item">
                  <Link
                    to="/profile"
                    className="nk-menu-link"
                    onClick={toggleSidebar}
                  >
                    <span className="nk-menu-icon">
                      <em className="icon ni ni-account-setting" />
                    </span>
                    <span className="nk-menu-text">My Profile</span>
                  </Link>
                </li>
                {user?.isAdmin && (
                  <li
                    className={`nk-menu-item has-sub ${
                      adminDropdownOpen ? "active" : ""
                    }`}
                  >
                    <a
                      href="#/"
                      className="nk-menu-link nk-menu-toggle"
                      onClick={toggleAdminDropdown}
                    >
                      <span className="nk-menu-icon">
                        <em className="icon ni ni-files" />
                      </span>
                      <span className="nk-menu-text">Admin Pages</span>
                    </a>
                    <ul className="nk-menu-sub">
                      <li className="nk-menu-item">
                        <Link
                          to="/admin/kyc"
                          className="nk-menu-link"
                          onClick={toggleSidebar}
                        >
                          <span className="nk-menu-text">KYC List</span>
                        </Link>
                      </li>
                      <li className="nk-menu-item">
                        <Link
                          to="/admin/transactions"
                          className="nk-menu-link"
                          onClick={toggleSidebar}
                        >
                          <span className="nk-menu-text">Transactions</span>
                        </Link>
                      </li>
                      <li className="nk-menu-item">
                        <Link
                          to="/admin/users"
                          className="nk-menu-link"
                          onClick={toggleSidebar}
                        >
                          <span className="nk-menu-text">Users</span>
                        </Link>
                      </li>
                      <li className="nk-menu-item">
                        <Link
                          to="/admin/wallets"
                          className="nk-menu-link"
                          onClick={toggleSidebar}
                        >
                          <span className="nk-menu-text">Wallets</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
