import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import banner from "../assets/banner_img.png";
import numberOne from "../assets/number-one.png";
import numberTwo from "../assets/number-2.png";
import numberThree from "../assets/number-3.png";
import { GrCheckmark } from "react-icons/gr";
import about from "../assets/undraw_crypto_portfolio_2jy5.svg";
import imageOne from "../assets/h2_choose_icon01.svg";
import imageTwo from "../assets/h2_choose_icon02.svg";
import imageThree from "../assets/h2_choose_icon03.svg";
import imageFour from "../assets/h2_choose_icon04.svg";
import { AiOutlineArrowDown } from "react-icons/ai";
import { AiOutlineArrowUp } from "react-icons/ai";
import contact from "../assets/contact_icon01.svg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { TfiClose } from "react-icons/tfi";
import { useSelector } from "react-redux";
import logo from "../assets/whinstein.png";

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [idToOpen, setIdToOpen] = useState(null);

  const user = useSelector((state) => state.user.currentUser);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const questions = [
    {
      id: 1,
      question: "What payment options are available?",
      answer: "For now we only accept Bitcoin and Ethereum as mode of payment.",
    },
    {
      id: 2,
      question: "How long would it take for my deposit to be confirmed?",
      answer:
        "Once you make a deposit and submit the required information your deposited funds would reflect on your dashboard.",
    },
    {
      id: 3,
      question: "How long after my deposit do I start making profits?",
      answer:
        "You should start making profits not more than 7 days after your confirmed deposit.",
    },
    {
      id: 4,
      question: "I'm having problems making payment. What do I do?",
      answer: "Kindly contact us via live support.",
    },
    {
      id: 5,
      question: "How long does it take for my withdrawal to be processed?",
      answer:
        "If you're eligible for withdrawal, you get your funds in about a few hours after your withdrawal request.",
    },
    {
      id: 6,
      question:
        "What payment method can I use in receiving my withdrawed funds?",
      answer:
        "You will be credited in Bitcoin or Ethereum to your chosen wallet address.",
    },
  ];

  return (
    <>
      <header className="bg-hero bg-cover bg-center h-[430px] md:h-[600px] lg:h-[680px] xl:h-[620px] w-full text-white px-[12px] md:px-[36px] xl:px-[100px] py-1">
        <nav className="flex justify-between items-center pt-2 lg:pt-8">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="w-[120px] lg:w-[180px] xl:w-[200px]"
            />
          </Link>
          {menuOpen ? (
            <button
              className="text-2xl lg:hidden z-50"
              style={{ color: "white" }}
              onClick={toggleMenu}
            >
              <TfiClose />
            </button>
          ) : (
            <button className="text-3xl lg:hidden" onClick={toggleMenu}>
              <GiHamburgerMenu />
            </button>
          )}

          <ul className="text-lg uppercase font-outfit items-center justify-between hidden lg:flex w-[620px]">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <a href="#about">About Us</a>
            </li>
            <li>
              <a href="#faq">Faq</a>
            </li>
            <li>
              <a href="#contact">Contact us</a>
            </li>
            {!user ? (
              <li className="py-[10px] px-[18px] bg-[#00C4F4] rounded-lg">
                <Link to="/login">Login</Link>
              </li>
            ) : (
              <li className="py-[10px] px-[18px] bg-[#00C4F4] rounded-lg">
                <Link to="/dashboard">Dashboard</Link>
              </li>
            )}
          </ul>
        </nav>

        {menuOpen ? (
          <div className="bg-[#0B1D33] visible opacity-[0.8] fixed right-0 top-0 w-full h-full z-[2]" />
        ) : (
          <div className="bg-[#0B1D33] invisible opacity-0 fixed right-0 top-0 w-full h-full z-[2]" />
        )}

        {menuOpen ? (
          <div className="fixed right-0 top-0 w-[300px] h-full opacity-[1] visible z-[40] translate-x-[0%] bg-[#0B1D33]">
            <ul className="mt-[100px] uppercase font-outfit text-lg">
              <li
                className="px-[25px] py-2 border-t-[1px] border-t-[rgb(255_255_255_/_6%)] border-b-[1px] border-b-[rgb(255_255_255_/_6%)]"
                onClick={() => setMenuOpen(false)}
              >
                <Link to="/">Home</Link>
              </li>
              <li
                className="px-[25px] py-2 border-t-[1px] border-t-[rgb(255_255_255_/_6%)] border-b-[1px] border-b-[rgb(255_255_255_/_6%)]"
                onClick={() => setMenuOpen(false)}
              >
                <a href="#about">About Us</a>
              </li>
              <li
                className="px-[25px] py-2 border-t-[1px] border-t-[rgb(255_255_255_/_6%)] border-b-[1px] border-b-[rgb(255_255_255_/_6%)]"
                onClick={() => setMenuOpen(false)}
              >
                <a href="#faq">Faq</a>
              </li>
              <li
                className="px-[25px] py-2 border-t-[1px] border-t-[rgb(255_255_255_/_6%)] border-b-[1px] border-b-[rgb(255_255_255_/_6%)]"
                onClick={() => setMenuOpen(false)}
              >
                <a href="#contact">Contact us</a>
              </li>
            </ul>
            {!user ? (
              <button className="uppercase font-outfit bg-[#00C4F4] block mx-auto mt-3 py-2 px-5 rounded-md">
                <Link to="/login">Login</Link>
              </button>
            ) : (
              <button className="uppercase font-outfit bg-[#00C4F4] block mx-auto mt-3 py-2 px-5 rounded-md">
                <Link to="/dashboard">Dashboard</Link>
              </button>
            )}
          </div>
        ) : (
          <div className="fixed right-0 top-0 w-[300px] h-full opacity-0 invisible z-50 translate-x-[101%]" />
        )}

        <div className="xl:w-[70%] mx-auto">
          <h1 className="text-4xl md:text-6xl font-extrabold text-center mt-12 lg:mt-24">
            World's Best Cryptocurrency Platform for you
          </h1>
          <img
            src={banner}
            alt="banner"
            className="mt-12 md:mt-16 lg:mt-20 xl:mt-16 md:w-[90%] lg:w-[80%] mx-auto"
          />
        </div>
      </header>

      <main className="mt-32 md:mt-48 text-[#030B15] text-center mb-12">
        <section className="px-[16px] md:px-[36px] xl:px-[100px]">
          <h2 className="font-extrabold text-4xl">
            Get the Whinstein Experience
          </h2>
          <div className="mt-9 md:mt-11 md:w-[90%] md:flex md:justify-between md:mx-auto">
            <div className="md:w-[30%]">
              <img
                src={numberOne}
                alt="one"
                className="w-[60px] md:w-[68px] xl:w-[80px] mx-auto"
              />
              <p className="text-[18px] md:text-[20px] mt-1 font-outfit">
                Register on our platform
              </p>
            </div>
            <div className="mt-[20px] md:mt-0 md:w-[30%]">
              <img
                src={numberTwo}
                alt="one"
                className="w-[60px] md:w-[68px] xl:w-[80px] mx-auto"
              />
              <p className="text-[18px] md:text-[20px] font-outfit mt-1">
                Start up your investment
              </p>
            </div>
            <div className="mt-[20px] md:mt-0 md:w-[30%]">
              <img
                src={numberThree}
                alt="one"
                className="w-[60px] md:w-[68px] xl:w-[80px] mx-auto"
              />
              <p className="text-[18px] md:text-[20px] font-outfit mt-1">
                Begin earning
              </p>
            </div>
          </div>

          <div
            className="mt-20 xl:mt-28 text-left lg:flex lg:justify-between lg:items-center"
            id="about"
          >
            <img
              src={about}
              alt="about"
              className="w-[80%] mx-auto mb-16 md:w-[70%] lg:w-[44%]"
            />
            <div className="lg:w-[46%]">
              <h3 className="uppercase text-[#564DCA] underline font-bold text-lg">
                About Whinstein
              </h3>
              <h4 className="text-black text-3xl font-bold my-6">
                Trusted by over 30,000+ users worldwide
              </h4>
              <p className="font-outfit text-base xl:text-lg mb-[12px]">
                The Whinstein Experience is all you need to unlock the full
                potential of the cryptocurrency world. Here's what we offer:
              </p>
              <p className="text-[15px] xl:text-[17px] font-outfit mb-1 flex">
                <GrCheckmark className="mt-[4px] mr-2" /> High-performance,
                innovative trading technology
              </p>
              <p className="text-[15px] xl:text-[17px] font-outfit mb-1 flex">
                <GrCheckmark className="mt-[4px] mr-2" /> Swift customer service
              </p>
              <p className="text-[15px] xl:text-[17px] font-outfit mb-1 flex">
                <GrCheckmark className="mt-[4px] mr-2" /> Self-service user
                dashboard
              </p>
              <p className="text-[15px] xl:text-[17px] font-outfit mb-1 flex">
                <GrCheckmark className="mt-[4px] mr-2" /> Fully licensed and
                regulated services
              </p>
              <button className="bg-[#564DCA] py-[10px] px-[20px] text-white font-outfit rounded-md mt-3">
                <Link to="/register">Get started</Link>
              </button>
            </div>
          </div>
        </section>

        <div className="mt-16 bg-choose bg-cover bg-center py-24 text-white font-outfit px-[18px]">
          <h3 className="text-lg uppercase relative inline px-[15px] font-extrabold before:content-[''] before:absolute before:left-0 before:top-1/2 before:bg-[#00C4F4] before:h-[10px] before:w-[10px] before:rounded-[50%] before:translate-y-[-50%] after:content-[''] after:absolute after:right-0 after:top-1/2 after:bg-[#00C4F4] after:h-[10px] after:w-[10px] after:rounded-[50%] after:translate-y-[-50%]">
            Why choose us
          </h3>

          <div className="mt-[36px] lg:mt-[60px] lg:flex lg:justify-between lg:text-left lg:px-[30px] xl:px-[120px]">
            <div className="lg:w-[22%]">
              <img src={imageOne} alt="icon" className="mx-[auto] lg:mx-0" />
              <h4 className="font-bold text-xl lg:text-2xl mt-3 mb-1">
                Swift payouts
              </h4>
              <p className="text-[#A4B4C3] lg:text-lg">
                We ensure swift disbursement of user funds upon withdrawal
                request.
              </p>
            </div>

            <div className="mt-[36px] lg:mt-0 lg:w-[22%]">
              <img src={imageTwo} alt="icon" className="mx-[auto] lg:mx-0" />
              <h4 className="font-bold text-xl lg:text-2xl mt-3 mb-1">
                Lifetime access
              </h4>
              <p className="text-[#A4B4C3] lg:text-lg">
                Upon registration all users have unlimited access to our
                financial services.
              </p>
            </div>

            <div className="mt-[36px] lg:mt-0 lg:w-[22%]">
              <img src={imageThree} alt="icon" className="mx-[auto] lg:mx-0" />
              <h4 className="font-bold text-xl lg:text-2xl mt-3 mb-1">
                User identity protection
              </h4>
              <p className="text-[#A4B4C3] lg:text-lg">
                Your identity is and will continually be safe with us.
              </p>
            </div>

            <div className="mt-[36px] lg:mt-0 lg:w-[22%]">
              <img src={imageFour} alt="icon" className="mx-[auto] lg:mx-0" />
              <h4 className="font-bold text-xl lg:text-2xl mt-3 mb-1">
                Funds security
              </h4>
              <p className="text-[#A4B4C3] lg:text-lg">
                We implement state of the art security measures to secure funds.
              </p>
            </div>
          </div>
        </div>

        <div
          className="bg-[#F3F6FC] py-20 px-[18px] md:px-[70px] lg:px-[150px] xl:px-[250px]"
          id="faq"
        >
          <h4 className="text-[28px] lg:text-[32px] text-black font-bold mb-10">
            Frequently Asked Questions
          </h4>

          {questions.map((q) => (
            <div
              className="bg-white px-3.5 py-4 rounded-lg mb-4 shadow-lg"
              key={q.id}
            >
              <section className="flex justify-between items-center">
                <h4 className="font-semibold text-left lg:text-lg w-[90%]">
                  {q.question}
                </h4>
                {q.id === idToOpen ? (
                  <button onClick={() => setIdToOpen(null)}>
                    <AiOutlineArrowUp className="text-lg lg:text-xl" />
                  </button>
                ) : (
                  <button onClick={() => setIdToOpen(q.id)}>
                    <AiOutlineArrowDown className="text-lg lg:text-xl" />
                  </button>
                )}
              </section>
              {q.id === idToOpen && (
                <p className="text-sm lg:text-base pt-4 px-1 text-[#727885] text-left">
                  {q.answer}
                </p>
              )}
            </div>
          ))}
        </div>

        <div className="bg-choose bg-center bg-cover pt-16 pb-32" id="contact">
          <h4 className="text-[28px] lg:text-[32px] text-white font-bold mb-10">
            Get In Touch
          </h4>

          <div className="px-[18px] md:px-[70px] lg:px-[50px] xl:px-[150px] lg:flex lg:justify-between">
            <div className="lg:w-[50%] xl:w-[45%]">
              <div
                className="p-[20px] border-[rgba(246,_246,_246,_0.06)] rounded-[11px] mb-[15px] flex items-center"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(255, 255, 255, 0.06)",
                  borderWidth: "1px",
                }}
              >
                <div className="w-[35px] mr-[22px] flex-[0_0_35px]">
                  <img src={contact} alt="contact" />
                </div>
                <div>
                  <p className="text-[15px] text-left font-outfit text-white leading-[146.49%]">
                    Chevy Chase, Maryland 20815 Wisconsin Ave, Suite 700
                  </p>
                </div>
              </div>
              <div
                className="p-[20px] border-[rgba(246,_246,_246,_0.06)] rounded-[11px] mb-[15px] flex items-center"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(255, 255, 255, 0.06)",
                  borderWidth: "1px",
                }}
              >
                <div className="w-[35px] mr-[22px] flex-[0_0_35px]">
                  <img src={contact} alt="contact" />
                </div>
                <div>
                  <a
                    className="text-[15px] text-left font-outfit text-white leading-[146.49%]"
                    href="mailto:support@whinstein.com"
                  >
                    support@whinstein.com
                  </a>
                </div>
              </div>
              <div
                className="p-[20px] border-[rgba(246,_246,_246,_0.06)] rounded-[11px] mb-[15px] flex items-center"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(255, 255, 255, 0.06)",
                  borderWidth: "1px",
                }}
              >
                <div className="w-[35px] mr-[22px] flex-[0_0_35px]">
                  <img src={contact} alt="contact" />
                </div>
                <div>
                  <p className="text-[15px] text-left font-outfit text-white leading-[146.49%]">
                    +1 (949) 392-3759
                  </p>
                </div>
              </div>
            </div>

            <div className="font-outfit mt-[80px] lg:mt-0 lg:w-[45%] xl:w-[50%]">
              <form>
                <input
                  type="text"
                  className="bg-[rgba(255,_255,_255,_0.06)] border-[1px] text-[20px] text-[#030B15] border-[rgba(246,_246,_246,_0.06)] shadow-[0px_4px_15px_rgb(0_0_0_/_25%)] rounded-[11px] h-[66px] w-full mb-[20px] py-[17px] px-[22px] focus:bg-white focus:border-white appearance-none"
                  placeholder="Name"
                />
                <input
                  type="email"
                  className="bg-[rgba(255,_255,_255,_0.06)] border-[1px] text-[20px] text-[#030B15] border-[rgba(246,_246,_246,_0.06)] shadow-[0px_4px_15px_rgb(0_0_0_/_25%)] rounded-[11px] h-[66px] w-full mb-[20px] py-[17px] px-[22px] focus:bg-white focus:border-white appearance-none"
                  placeholder="Email address"
                />
                <textarea
                  className="bg-[rgba(255,_255,_255,_0.06)] border-[1px] text-[20px] text-[#030B15] border-[rgba(246,_246,_246,_0.06)] shadow-[0px_4px_15px_rgb(0_0_0_/_25%)] rounded-[11px] h-[165px] w-full mb-[20px] py-[17px] px-[22px] focus:bg-white focus:border-white appearance-none"
                  placeholder="Your message"
                />
                <button
                  type="submit"
                  className="bg-[#00C4F4] py-[15px] font-semibold px-[25px] rounded-[5px] text-[13px] text-white uppercase"
                >
                  Send message
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="bg-white py-[30px] xl:py-[50px] px-[25px] w-[90%] md:w-[80%] lg:w-[65%] mx-auto shadow-[0px_26px_23px_rgb(81_98_132_/_6%)] rounded-[10px] relative mt-[-80px]">
          <h5 className="text-[#030B15] font-extrabold text-[24px]">
            Subscribe to our Newsletter
          </h5>
          <form>
            <input
              type="email"
              className="border-[1px] font-outfit border-[#ECEEF3] w-full xl:w-[60%] rounded-[4px] text-[16px] bg-[#F3F6FC] py-[22px] px-[19px] my-6 appearance-none"
              placeholder="Enter your email"
            />
            <button
              type="submit"
              className="py-[18px] font-outfit px-[44px] bg-[#564DCA] rounded-[5px] text-[14px] uppercase text-white font-extrabold"
            >
              Subscribe
            </button>
          </form>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Home;
