import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";

const Users = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [toolsActive, setToolsActive] = useState(false);
  const [displayedUsers, setDisplayedUsers] = useState(10);
  const [usersToDisplay, setUsersToDisplay] = useState(displayedUsers);
  const [suspendModalOpen, setSuspendModalOpen] = useState(false);
  const [unsuspendModalOpen, setUnsuspendModalOpen] = useState(false);
  const [idToSuspend, setIdtoSuspend] = useState("");
  const [idToUnsuspend, setIdtoUnsuspend] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchingForUser, setSearchingForUser] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);

  const loadMoreUsers = () => {
    setUsersToDisplay(usersToDisplay + displayedUsers);
  };

  const toggleSearchActive = () => {
    setSearchActive(!searchActive);
  };

  const toggleToolsActive = () => {
    setToolsActive(!toolsActive);
  };

  const suspendUser = async () => {
    const token = localStorage.getItem("whinsteintkn");
    setLoading(true);

    try {
      await axios.put(
        `https://api.whinstein.com/api/user/suspend/${user?._id}`,
        { idToSuspend },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setSuspendModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const unsuspendUser = async () => {
    const token = localStorage.getItem("whinsteintkn");
    setLoading(true);

    try {
      await axios.put(
        `https://api.whinstein.com/api/user/unsuspend/${user?._id}`,
        { idToUnsuspend },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setUnsuspendModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("whinsteintkn");

    const getUsers = async () => {
      const { data } = await axios.get(
        `https://api.whinstein.com/api/user/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setUsers(data);
      setPageLoading(false);
    };

    getUsers();
  }, [user?._id]);

  useEffect(() => {
    if (searchingForUser) {
      const foundUser = users?.filter((user) => {
        return Object.values(user.name)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(foundUser);

      if (foundUser.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  }, [searchingForUser, searchTerm, users]);

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm">
                    <div className="nk-block-between">
                      <div className="nk-block-head-content">
                        <h3 className="nk-block-title page-title">Users</h3>
                        <div className="nk-block-des text-soft">
                          <p>You have total {users?.length} users.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="card card-bordered card-stretch">
                      <div className="card-inner-group">
                        <div className="card-inner position-relative card-tools-toggle">
                          <div className="card-title-group">
                            <div className="card-tools"></div>
                            <div className="card-tools me-n1">
                              <ul className="btn-toolbar gx-1">
                                <li>
                                  <a
                                    href="#/"
                                    onClick={toggleSearchActive}
                                    className="btn btn-icon search-toggle toggle-search"
                                    data-target="search"
                                  >
                                    <em className="icon ni ni-search" />
                                  </a>
                                </li>
                                <li className="btn-toolbar-sep" />
                                <li>
                                  <div className="toggle-wrap">
                                    <a
                                      href="#/"
                                      onClick={toggleToolsActive}
                                      className="btn btn-icon btn-trigger toggle"
                                      data-target="cardTools"
                                    >
                                      <em className="icon ni ni-menu-right" />
                                    </a>
                                    <div
                                      className={`toggle-content ${
                                        toolsActive ? "content-active" : ""
                                      }`}
                                      data-content="cardTools"
                                    >
                                      <ul className="btn-toolbar gx-1">
                                        <li className="toggle-close">
                                          <a
                                            href="#/"
                                            onClick={toggleToolsActive}
                                            className="btn btn-icon btn-trigger toggle"
                                            data-target="cardTools"
                                          >
                                            <em className="icon ni ni-arrow-left" />
                                          </a>
                                        </li>

                                        <li>
                                          <div className="dropdown">
                                            <a
                                              href="#/"
                                              className="btn btn-trigger btn-icon dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                            >
                                              <em className="icon ni ni-setting" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                                              <ul className="link-check">
                                                <li>
                                                  <span>Show</span>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setDisplayedUsers(10)
                                                  }
                                                  className={
                                                    displayedUsers === 10
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <a href="#/">10</a>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setDisplayedUsers(20)
                                                  }
                                                  className={
                                                    displayedUsers === 20
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <a href="#/">20</a>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setDisplayedUsers(50)
                                                  }
                                                  className={
                                                    displayedUsers === 50
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <a href="#/">50</a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            className={`card-search search-wrap ${
                              searchActive ? "active" : ""
                            }`}
                            data-search="search"
                          >
                            <div className="card-body">
                              <div className="search-content">
                                <a
                                  href="#/"
                                  onClick={toggleSearchActive}
                                  className="search-back btn btn-icon toggle-search"
                                  data-target="search"
                                >
                                  <em className="icon ni ni-arrow-left" />
                                </a>
                                <input
                                  type="text"
                                  className="form-control border-transparent form-focus-none"
                                  placeholder="Search by user name"
                                  onChange={(e) => {
                                    setSearchingForUser(true);
                                    setSearchTerm(e.target.value);
                                  }}
                                />
                                <button className="search-submit btn btn-icon">
                                  <em className="icon ni ni-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-inner p-0">
                          <div className="nk-tb-list nk-tb-ulist">
                            <div className="nk-tb-item nk-tb-head">
                              <div className="nk-tb-col">
                                <span className="sub-text">User</span>
                              </div>
                              <div className="nk-tb-col tb-col-mb">
                                <span className="sub-text">Balance</span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="sub-text">Phone</span>
                              </div>
                              <div className="nk-tb-col tb-col-lg">
                                <span className="sub-text">Verified</span>
                              </div>
                              <div className="nk-tb-col tb-col-lg">
                                <span className="sub-text">Date Joined</span>
                              </div>
                              <div className="nk-tb-col tb-col-md">
                                <span className="sub-text">Status</span>
                              </div>
                              <div className="nk-tb-col nk-tb-col-tools text-end"></div>
                            </div>
                            {!searchTerm && users?.length > 0 && (
                              <>
                                {users
                                  ?.slice(0, usersToDisplay)
                                  ?.map((user) => (
                                    <div className="nk-tb-item" key={user._id}>
                                      <div className="nk-tb-col">
                                        <Link to={`/admin/user/${user._id}`}>
                                          <div className="user-card">
                                            <div className="user-avatar bg-primary">
                                              <span>
                                                {user?.name
                                                  .match(/(^\S\S?|\b\S)?/g)
                                                  .join("")
                                                  .match(/(^\S|\S$)?/g)
                                                  .join("")
                                                  .toUpperCase()}
                                              </span>
                                            </div>
                                            <div className="user-info">
                                              <span className="tb-lead">
                                                {user.name}
                                                {user.isBlocked ? (
                                                  <span className="dot dot-danger d-md-none ms-1" />
                                                ) : (
                                                  <span className="dot dot-success d-md-none ms-1" />
                                                )}
                                              </span>
                                              <span>{user.email}</span>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                      <div className="nk-tb-col tb-col-mb">
                                        <span className="tb-amount">
                                          {user.balance}
                                          <span className="currency"> USD</span>
                                        </span>
                                      </div>
                                      <div className="nk-tb-col tb-col-md">
                                        <span>{user.number}</span>
                                      </div>
                                      <div className="nk-tb-col tb-col-lg">
                                        <ul className="list-status">
                                          {user.verified ? (
                                            <li>
                                              <em className="icon text-success ni ni-check-circle" />
                                              <span>Email</span>
                                            </li>
                                          ) : (
                                            <li>
                                              <em className="icon text-warning ni ni-alert-circle" />
                                              <span>Email</span>
                                            </li>
                                          )}
                                          {user.kycStatus === "Approved" ? (
                                            <li>
                                              <em className="icon ni ni-check-circle text-success" />
                                              <span>KYC</span>
                                            </li>
                                          ) : (
                                            <li>
                                              <em className="icon ni ni-alert-circle text-warning" />
                                              <span>KYC</span>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                      <div className="nk-tb-col tb-col-lg">
                                        <span>
                                          {new Date(
                                            user?.createdAt
                                          ).toLocaleDateString()}
                                        </span>
                                      </div>
                                      <div className="nk-tb-col tb-col-md">
                                        {user.isBlocked ? (
                                          <span className="tb-status text-danger">
                                            Suspended
                                          </span>
                                        ) : (
                                          <span className="tb-status text-success">
                                            Active
                                          </span>
                                        )}
                                      </div>
                                      <div className="nk-tb-col nk-tb-col-tools">
                                        <ul className="nk-tb-actions gx-1">
                                          <li>
                                            <div className="drodown">
                                              <a
                                                href="#/"
                                                className="dropdown-toggle btn btn-icon btn-trigger"
                                                data-bs-toggle="dropdown"
                                              >
                                                <em className="icon ni ni-more-h" />
                                              </a>
                                              <div className="dropdown-menu dropdown-menu-end">
                                                <ul className="link-list-opt no-bdr">
                                                  <li>
                                                    <Link
                                                      to={`/admin/user/${user._id}`}
                                                    >
                                                      <em className="icon ni ni-eye" />
                                                      <span>View Details</span>
                                                    </Link>
                                                  </li>
                                                  <li className="divider" />
                                                  {!user.isBlocked ? (
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setSuspendModalOpen(
                                                            true
                                                          );
                                                          setIdtoSuspend(
                                                            user._id
                                                          );
                                                        }}
                                                      >
                                                        <em className="icon ni ni-na" />
                                                        <span>
                                                          Suspend User
                                                        </span>
                                                      </a>
                                                    </li>
                                                  ) : (
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setUnsuspendModalOpen(
                                                            true
                                                          );
                                                          setIdtoUnsuspend(
                                                            user._id
                                                          );
                                                        }}
                                                      >
                                                        <em className="icon ni ni-na" />
                                                        <span>
                                                          Unsuspend User
                                                        </span>
                                                      </a>
                                                    </li>
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ))}
                              </>
                            )}
                            {searchTerm && searchResults?.length > 0 && (
                              <>
                                {searchResults?.map((user) => (
                                  <div className="nk-tb-item" key={user._id}>
                                    <div className="nk-tb-col">
                                      <Link to={`/admin/user/${user._id}`}>
                                        <div className="user-card">
                                          <div className="user-avatar bg-primary">
                                            <span>
                                              {user?.name
                                                .match(/(^\S\S?|\b\S)?/g)
                                                .join("")
                                                .match(/(^\S|\S$)?/g)
                                                .join("")
                                                .toUpperCase()}
                                            </span>
                                          </div>
                                          <div className="user-info">
                                            <span className="tb-lead">
                                              {user.name}
                                              {user.isBlocked ? (
                                                <span className="dot dot-danger d-md-none ms-1" />
                                              ) : (
                                                <span className="dot dot-success d-md-none ms-1" />
                                              )}
                                            </span>
                                            <span>{user.email}</span>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="nk-tb-col tb-col-mb">
                                      <span className="tb-amount">
                                        35,040.34
                                        <span className="currency">USD</span>
                                      </span>
                                    </div>
                                    <div className="nk-tb-col tb-col-md">
                                      <span>{user.number}</span>
                                    </div>
                                    <div className="nk-tb-col tb-col-lg">
                                      <ul className="list-status">
                                        {user.verified ? (
                                          <li>
                                            <em className="icon text-success ni ni-check-circle" />
                                            <span>Email</span>
                                          </li>
                                        ) : (
                                          <li>
                                            <em className="icon text-warning ni ni-alert-circle" />
                                            <span>Email</span>
                                          </li>
                                        )}
                                        {user.kycStatus === "Approved" ? (
                                          <li>
                                            <em className="icon ni ni-check-circle text-success" />
                                            <span>KYC</span>
                                          </li>
                                        ) : (
                                          <li>
                                            <em className="icon ni ni-alert-circle text-warning" />
                                            <span>KYC</span>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                    <div className="nk-tb-col tb-col-lg">
                                      <span>
                                        {new Date(
                                          user?.createdAt
                                        ).toLocaleDateString()}
                                      </span>
                                    </div>
                                    <div className="nk-tb-col tb-col-md">
                                      {user.isBlocked ? (
                                        <span className="tb-status text-danger">
                                          Suspended
                                        </span>
                                      ) : (
                                        <span className="tb-status text-success">
                                          Active
                                        </span>
                                      )}
                                    </div>
                                    <div className="nk-tb-col nk-tb-col-tools">
                                      <ul className="nk-tb-actions gx-1">
                                        <li>
                                          <div className="drodown">
                                            <a
                                              href="#/"
                                              className="dropdown-toggle btn btn-icon btn-trigger"
                                              data-bs-toggle="dropdown"
                                            >
                                              <em className="icon ni ni-more-h" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-end">
                                              <ul className="link-list-opt no-bdr">
                                                <li>
                                                  <Link
                                                    to={`/admin/user/${user._id}`}
                                                  >
                                                    <em className="icon ni ni-eye" />
                                                    <span>View Details</span>
                                                  </Link>
                                                </li>
                                                <li className="divider" />
                                                <li>
                                                  <a
                                                    href="#/"
                                                    onClick={() => {
                                                      setSuspendModalOpen(true);
                                                      setIdtoSuspend(user._id);
                                                    }}
                                                  >
                                                    <em className="icon ni ni-na" />
                                                    <span>Suspend User</span>
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                            {(noResults || users?.length === 0) && (
                              <p
                                style={{
                                  position: "relative",
                                  left: "50%",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                No records
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {usersToDisplay < users?.length && (
                  <div className="text-center pt-4">
                    <a
                      href="#/"
                      className="link link-soft"
                      onClick={loadMoreUsers}
                    >
                      <em className="icon ni ni-redo" />
                      <span>Load More</span>
                    </a>
                  </div>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {suspendModalOpen && (
        <>
          <div className="modal-backdrop fade show" />
          <div
            className="modal fade show"
            id="deleteEventPopup"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal py-4">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Are You Sure?</h4>
                    <ul className="d-flex justify-content-center gx-4 mt-4">
                      <li>
                        {loading ? (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            disabled
                          >
                            Suspending...
                          </button>
                        ) : (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            onClick={suspendUser}
                          >
                            Yes, Suspend
                          </button>
                        )}
                      </li>
                      <li>
                        <button
                          className="btn btn-danger btn-dim"
                          onClick={() => setSuspendModalOpen(false)}
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {unsuspendModalOpen && (
        <>
          <div className="modal-backdrop fade show" />
          <div
            className="modal fade show"
            id="deleteEventPopup"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal py-4">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-info" />
                    <h4 className="nk-modal-title">Are You Sure?</h4>
                    <ul className="d-flex justify-content-center gx-4 mt-4">
                      <li>
                        {loading ? (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            disabled
                          >
                            Unsuspending...
                          </button>
                        ) : (
                          <button
                            id="deleteEvent"
                            className="btn btn-success"
                            onClick={unsuspendUser}
                          >
                            Yes, Unsuspend
                          </button>
                        )}
                      </li>
                      <li>
                        <button
                          className="btn btn-danger btn-dim"
                          onClick={() => setUnsuspendModalOpen(false)}
                        >
                          Cancel
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Loading
        loading={pageLoading}
        background="rgba(255,255,255)"
        loaderColor="#0971fe"
      />

      <ToastContainer
        position="bottom-center"
        theme="light"
        autoClose={3500}
        closeButton={false}
      />
    </div>
  );
};

export default Users;
