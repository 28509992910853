import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";

const Notifications = () => {
  const [sideOpen, setSideOpen] = useState(false);

  const user = useSelector((state) => state.user.currentUser);

  const initials = user?.name
    .match(/(^\S\S?|\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();

  const toggleSide = () => {
    setSideOpen(!sideOpen);
  };

  return (
    <div className="nk-body npc-crypto bg-white has-sidebar">
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar />
          <div className="nk-wrap ">
            <Header />
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <div className="nk-block">
                    <div className="card card-bordered">
                      <div className="card-aside-wrap">
                        <div className="card-inner card-inner-lg">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between">
                              <div className="nk-block-head-content">
                                <h4
                                  className="nk-block-title"
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    marginBottom: "8px",
                                  }}
                                >
                                  Notification Settings
                                </h4>
                                <div className="nk-block-des">
                                  <p>
                                    You will get only notifications that have
                                    been enabled.
                                  </p>
                                </div>
                              </div>
                              <div className="nk-block-head-content align-self-start d-lg-none">
                                <a
                                  href="#/"
                                  onClick={toggleSide}
                                  className="toggle btn btn-icon btn-trigger mt-n1"
                                  data-target="userAside"
                                >
                                  <em className="icon ni ni-menu-alt-r" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-head-content">
                              <h6 className="text-[16px] font-[700]">
                                Security Alerts
                              </h6>
                              <p>
                                You will get only those email notification what
                                you want.
                              </p>
                            </div>
                          </div>
                          <div className="nk-block-content">
                            <div className="gy-3">
                              <div className="g-item">
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    defaultChecked
                                    id="unusual-activity"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="unusual-activity"
                                  >
                                    Email me whenever there's encounter unusual
                                    activity
                                  </label>
                                </div>
                              </div>
                              <div className="g-item">
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="new-browser"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="new-browser"
                                  >
                                    Email me if new browser is used to sign in
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-head-content">
                              <h6 className="text-[16px] font-[700]">News</h6>
                              <p>
                                You will get only those email notification what
                                you want.
                              </p>
                            </div>
                          </div>
                          <div className="nk-block-content">
                            <div className="gy-3">
                              <div className="g-item">
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    defaultChecked
                                    id="latest-sale"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="latest-sale"
                                  >
                                    Notify me by email about sales and latest
                                    news
                                  </label>
                                </div>
                              </div>
                              <div className="g-item">
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="feature-update"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="feature-update"
                                  >
                                    Email me about new features and updates
                                  </label>
                                </div>
                              </div>
                              <div className="g-item">
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    defaultChecked
                                    id="account-tips"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="account-tips"
                                  >
                                    Email me about tips on using account
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg toggle-break-lg ${
                            sideOpen ? "content-active" : ""
                          }`}
                          data-toggle-body="true"
                          data-content="userAside"
                          data-toggle-screen="lg"
                          data-toggle-overlay="true"
                        >
                          <div className="card-inner-group">
                            <div className="card-inner">
                              <div className="user-card">
                                <div className="user-avatar bg-primary">
                                  <span>{initials}</span>
                                </div>
                                <div className="user-info">
                                  <span className="lead-text">
                                    {user?.name}
                                  </span>
                                  <span className="sub-text">
                                    {user?.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="card-inner p-0">
                              <ul className="link-list-menu">
                                <li>
                                  <Link to="/profile">
                                    <em className="icon ni ni-user-fill-c" />
                                    <span>Personal Infomation</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link className="active" to="/notifications">
                                    <em className="icon ni ni-bell-fill" />
                                    <span>Notifications</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/activity">
                                    <em className="icon ni ni-activity-round-fill" />
                                    <span>Account Activity</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/settings">
                                    <em className="icon ni ni-lock-alt-fill" />
                                    <span>Security Settings</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {sideOpen && (
        <div
          className="toggle-overlay"
          data-target="userAside"
          onClick={toggleSide}
        />
      )}
    </div>
  );
};

export default Notifications;
