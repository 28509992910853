import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { loadingUser, loadUser, noUser, logoutUser } from "./store/userRedux";
import Home from "./pages/Home";
import KycApplication from "./pages/auth/KycApplication";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import VerifyEmail from "./pages/auth/VerifyEmail";
import KycForm from "./pages/auth/KycForm";
import KycSubmitted from "./pages/auth/KycSubmitted";
import Dashboard from "./pages/user/Dashboard";
import Users from "./pages/admin/Users";
import UserDetails from "./pages/admin/UserDetails";
import Kyc from "./pages/admin/Kyc";
import KycDetails from "./pages/admin/KycDetails";
import Transactions from "./pages/admin/Transactions";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Activity from "./pages/user/Activity";
import Deposit from "./pages/user/Deposit";
import Profile from "./pages/user/Profile";
import Notifications from "./pages/user/Notifications";
import Settings from "./pages/user/Settings";
import UserTransactions from "./pages/user/UserTransactions";
import EmailVerified from "./pages/auth/EmailVerified";
import NotFound from "./pages/NotFound";
import Withdraw from "./pages/user/Withdraw";
import PasswordReset from "./pages/auth/PasswordReset";
import Wallets from "./pages/admin/Wallets";

const App = () => {
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingUser());
    const token = localStorage.getItem("whinsteintkn");

    const getUser = async () => {
      try {
        const { data } = await axios.get("https://api.whinstein.com/api/user", {
          headers: { token: `Bearer ${token}` },
        });

        dispatch(loadUser(data));
      } catch (error) {
        if (error.response.data === "Token is not valid!") {
          localStorage.removeItem("whinsteintkn");
          dispatch(logoutUser());
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/activity"
        element={user ? <Activity /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/admin/kyc"
        element={
          user?.isAdmin ? <Kyc /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/admin/kyc/:id"
        element={
          user?.isAdmin ? (
            <KycDetails />
          ) : (
            <Navigate replace to={"/dashboard"} />
          )
        }
      />
      <Route
        path="/admin/transactions"
        element={
          user?.isAdmin ? (
            <Transactions />
          ) : (
            <Navigate replace to={"/dashboard"} />
          )
        }
      />
      <Route
        path="/admin/users"
        element={
          user?.isAdmin ? <Users /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/admin/user/:id"
        element={
          user?.isAdmin ? (
            <UserDetails />
          ) : (
            <Navigate replace to={"/dashboard"} />
          )
        }
      />
      <Route
        path="/admin/wallets"
        element={
          user?.isAdmin ? <Wallets /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/dashboard"
        element={user ? <Dashboard /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/deposit"
        element={user ? <Deposit /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/forgot-password"
        element={
          !user ? <ForgotPassword /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/kyc-application"
        element={
          !user ? <KycApplication /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/kyc-form"
        element={!user ? <KycForm /> : <Navigate replace to={"/dashboard"} />}
      />
      <Route
        path="/kyc-submitted"
        element={
          !user ? <KycSubmitted /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/login"
        element={!user ? <Login /> : <Navigate replace to={"/dashboard"} />}
      />
      <Route
        path="/notifications"
        element={user ? <Notifications /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/passwordreset/:resetToken"
        element={
          !user ? <PasswordReset /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/profile"
        element={user ? <Profile /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/register"
        element={!user ? <Register /> : <Navigate replace to={"/dashboard"} />}
      />
      <Route
        path="/settings"
        element={user ? <Settings /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/transactions"
        element={
          user ? <UserTransactions /> : <Navigate replace to={"/login"} />
        }
      />
      <Route
        path="/verified"
        element={
          !user ? <EmailVerified /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/verify-email"
        element={
          !user ? <VerifyEmail /> : <Navigate replace to={"/dashboard"} />
        }
      />
      <Route
        path="/withdraw"
        element={user ? <Withdraw /> : <Navigate replace to={"/login"} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
